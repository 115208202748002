import './assets/icomoon/font.css';

import {SketchBackgroundbox} from './components/sketchBackgroundbox.js';
import {SketchButton} from './components/sketchButton.js';
// import {SketchCard} from './components/SketchCard.js';
import {SketchCheckbox} from './components/SketchCheckbox.js';
import {SketchCheckboxgroup} from './components/SketchCheckboxgroup.js';
import {SketchCollapseSection} from './components/SketchCollapseSection.js';
import {SketchDate} from './components/sketchDate.js';
import {SketchEditor} from './components/SketchEditor.js';
import {SketchGrid} from './components/sketchGrid.js';
import {SketchHeading} from './components/sketchHeading.js';
import {SketchHighlightEditor} from './components/SketchHighlightEditor.js';
import {SketchImage} from './components/SketchImage.js';
import {SketchIcon} from './components/SketchIcon.js';
import {SketchInput} from './components/sketchInput.js';
import {SketchJsonDiff} from './components/SketchJsonDiff.js';
import {SketchLink} from './components/SketchLink.js';
import {SketchLoader} from './components/SketchLoader.js';
import {SketchMap} from './components/SketchMap.js';
import {SketchMessage} from './components/SketchMessage.js';
import {SketchModal} from './components/SketchModal.js';
import {SketchProgressbar} from './components/SketchProgressbar.js';
import {SketchRepeatRule} from './components/complexComponents/SketchRepeatRule.js';
import {SketchSelect} from './components/SketchSelect.js';
import {SketchSkeletonBox} from './components/SketchSkeletonBox.js';
import {SketchTabsection} from './components/SketchTabsection.js';
import {SketchText} from './components/sketchText.js';
import {SketchTime} from './components/SketchTime.js';
import {SketchWizard} from './components/SketchWizard.js';
import {SketchHtmlWysiwig} from './components/SketchHtmlWysiwig.js';

customElements.define('sketch-backgroundbox', SketchBackgroundbox);
customElements.define('sketch-button', SketchButton);
// customElements.define('sketch-card', SketchCard);
customElements.define('sketch-checkbox', SketchCheckbox);
customElements.define('sketch-checkboxgroup', SketchCheckboxgroup);
customElements.define('sketch-collapsesection', SketchCollapseSection);
customElements.define('sketch-date', SketchDate);
customElements.define('sketch-editor', SketchEditor);
customElements.define('sketch-grid', SketchGrid);
customElements.define('sketch-heading', SketchHeading);
customElements.define('sketch-highlighteditor', SketchHighlightEditor);
customElements.define('sketch-icon', SketchIcon);
customElements.define('sketch-image', SketchImage);
customElements.define('sketch-input', SketchInput);
customElements.define('sketch-jsondiff', SketchJsonDiff);
customElements.define('sketch-link', SketchLink);
customElements.define('sketch-loader', SketchLoader);
customElements.define('sketch-map', SketchMap);
customElements.define('sketch-message', SketchMessage);
customElements.define('sketch-modal', SketchModal);
customElements.define('sketch-progressbar', SketchProgressbar);
customElements.define('sketch-repeatrule', SketchRepeatRule);
customElements.define('sketch-skeletonbox', SketchSkeletonBox);
customElements.define('sketch-select', SketchSelect);
customElements.define('sketch-tabsection', SketchTabsection);
customElements.define('sketch-text', SketchText);
customElements.define('sketch-time', SketchTime);
customElements.define('sketch-wizard', SketchWizard);
customElements.define('sketch-htmlwysiwig', SketchHtmlWysiwig);
