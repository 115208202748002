import {isBooleanAttributeTrue} from '../utils/index.js';

export class SketchWizard extends HTMLElement {
    /* for form elements */
    static formAssociated = true;

    $shadowRoot;

    $internals;

    $numberOfSteps;

    $fallbackmode = false;

    static get observedAttributes() {
        return [
            'activestep',
            'steptitles',
            'previousbuttontext',
            'nextbuttontext',
            'finishbuttontext',
            'externallymanaged',
            'isloading',
        ];
    }

    constructor() {
        super();
        /* for form elements */
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: true,
        });
        try {
            this.$internals = this.attachInternals();
        } catch (error) {
            this.$fallbackmode = true;
        }
    }

    get activestep() {
        return Number(this.getAttribute('activestep'));
    }

    set activestep(value) {
        this.setAttribute('activestep', value);
    }

    get steptitles() {
        return this.getAttribute('steptitles');
    }

    set steptitles(value) {
        this.setAttribute('steptitles', value);
    }

    get previousbuttontext() {
        return this.getAttribute('previousbuttontext') || 'Vorige';
    }

    set previousbuttontext(value) {
        this.setAttribute('previousbuttontext', value);
    }

    get nextbuttontext() {
        return this.getAttribute('nextbuttontext') || 'Volgende';
    }

    set nextbuttontext(value) {
        this.setAttribute('nextbuttontext', value);
    }

    get finishbuttontext() {
        return this.getAttribute('finishbuttontext') || 'Voltooi';
    }

    set finishbuttontext(value) {
        this.setAttribute('finishbuttontext', value);
    }

    get fallbackmode() {
        return isBooleanAttributeTrue(this.getAttribute('fallbackmode'));
    }

    set fallbackmode(value) {
        this.setAttribute('fallbackmode', value);
    }

    get isloading() {
        return isBooleanAttributeTrue(this.getAttribute('isloading'));
    }

    set isloading(value) {
        this.setAttribute('isloading', value);
    }

    get externallymanaged() {
        return isBooleanAttributeTrue(this.getAttribute('externallymanaged'));
    }

    set externallymanaged(value) {
        this.setAttribute('externallymanaged', value);
    }

    connectedCallback() {
        if (this.$fallbackmode) {
            this.fallbackmode = true;
        }
        this.render();
        if (!this.fallbackmode) {
            this.$internals.setFormValue(this.activestep);
        }
        this._bind();
    }

    attributeChangedCallback(property) {
        if (property === 'isloading') {
            let button = null;
            if (this.activestep !== this.$numberOfSteps) {
                button = this.$shadowRoot.querySelector(
                    `#stepwrapper-${this.activestep} #nextbutton`
                );
            } else {
                button = this.$shadowRoot.querySelector(
                    `#stepwrapper-${this.activestep} #finishbutton`
                );
            }
            button?.setAttribute('isloading', this.isloading);
        } else {
            this._unbind();
            this.render();
            this._bind();
        }
    }

    _bind = () => {
        this.$shadowRoot
            .querySelectorAll('.wizardstep.completed')
            .forEach((step) => {
                step.addEventListener('click', () =>
                    this._handleStepClick(step)
                );
            });
        this.$shadowRoot.querySelectorAll('#backbutton').forEach((button) => {
            button.addEventListener('sk-click', this._handleBackButtonClick);
        });
        this.$shadowRoot.querySelectorAll('#nextbutton').forEach((button) => {
            button.addEventListener('sk-click', this._handleNextButtonClick);
        });
        this.$shadowRoot.querySelectorAll('#finishbutton').forEach((button) => {
            button.addEventListener('sk-click', this._handleFinishButtonClick);
        });
    };

    _unbind = () => {
        this.$shadowRoot
            .querySelectorAll('.wizardstep.completed')
            .forEach((step) => {
                step.removeEventListener('click', () =>
                    this._handleStepClick(step)
                );
            });
        this.$shadowRoot
            .querySelector('#backbutton')
            ?.removeEventListener('sk-click', this._handleBackButtonClick);
        this.$shadowRoot
            .querySelector('#nextbutton')
            ?.removeEventListener('sk-click', this._handleNextButtonClick);
        this.$shadowRoot
            .querySelector('#finishbutton')
            ?.removeEventListener('sk-click', this._handleFinishButtonClick);
    };

    _handleBackButtonClick = () => {
        if (!this.externallymanaged) {
            this._changeActiveStep(this.activestep - 1);
        } else {
            this.dispatchEvent(
                new CustomEvent('previoussteprequested', {
                    bubbles: true,
                    composed: true,
                    detail: {
                        currentStep: this.activestep,
                    },
                })
            );
        }
    };

    _handleNextButtonClick = () => {
        if (!this.externallymanaged) {
            this._changeActiveStep(this.activestep + 1);
        } else {
            this.dispatchEvent(
                new CustomEvent('nextsteprequested', {
                    bubbles: true,
                    composed: true,
                    detail: {
                        currentStep: this.activestep,
                    },
                })
            );
        }
    };

    _handleFinishButtonClick = () => {
        this.dispatchEvent(
            new CustomEvent('wizardcompleted', {
                bubbles: true,
                composed: true,
            })
        );
    };

    _renderWizardSteps = () =>
        this.steptitles
            .split(';')
            .map((title, index) => this._renderStep(title, index))
            .join('');

    _renderStep = (title, tabIndex) => {
        const stepIndex = tabIndex + 1;
        const isActive = this.activestep === stepIndex;
        const isCompleted = this.activestep > stepIndex;

        return `${
            stepIndex > 1
                ? `
                <div class="arrow ${isActive ? 'active' : ''} ${isCompleted ? 'completed' : ''}"><sketch-icon icon="angle-double-right"></sketch-icon></div>`
                : ''
        }
        <div class="wizardstep ${isActive ? 'active' : ''} ${isCompleted ? 'completed' : ''}" data-tabindex="${stepIndex}">
            ${title}
        </div>`;
    };

    _renderSlots = () =>
        this.steptitles
            .split(';')
            .map((_, index) => {
                let cssClass = '';
                if (this.activestep === index + 1) {
                    cssClass = 'active';
                }
                if (this.activestep > index + 1) {
                    cssClass = 'past';
                }
                if (this.activestep < index + 1) {
                    cssClass = 'future';
                }
                return `
                <div 
                    class="slotwrapper ${index === 0 ? 'firststep' : ''} ${index + 1 === this.$numberOfSteps ? 'finalstep' : ''} ${cssClass}" 
                    id="stepwrapper-${index + 1}"  >
                    <slot
                        name="step-${index + 1}"
                    ></slot>
                    <div class="wizardbuttons">${this._renderButtons()}</div>
                </div>`;
            })
            .join('');

    _handleStepClick = (event) => {
        this._changeActiveStep(event.dataset.tabindex);
    };

    _changeActiveStep = (newStepNumber) => {
        this.activestep = newStepNumber;
        this.dispatchEvent(
            new CustomEvent('stepchange', {
                bubbles: true,
                composed: true,
                detail: {
                    step: newStepNumber,
                },
            })
        );
    };

    _renderButtons = () => `
            <sketch-button 
                id="backbutton" 
                label="${this.previousbuttontext}" 
                icon="angle-double-left" 
                iconPosition="before">
            </sketch-button>
            <sketch-button 
                id="nextbutton" 
                variant="primary" 
                label="${this.nextbuttontext}" 
                icon="angle-double-right" 
                iconPosition="after"
                ${this.activestep !== this.$numberOfSteps && this.isloading ? 'isloading' : ''}>
            </sketch-button>
            <sketch-button 
                id="finishbutton" 
                variant="primary" 
                label="${this.finishbuttontext}" 
                icon="angle-double-right" 
                iconPosition="after"
                ${this.activestep === this.$numberOfSteps && this.isloading ? 'isloading' : ''}>
            </sketch-button>`;

    render() {
        this.$numberOfSteps = this.steptitles.split(';').length;
        this.$shadowRoot.innerHTML = `
        <style>
            * {
                box-sizing: border-box;
            }
            .wizardsteps {
                display: flex;
                flex-direction: column;
            }
            .arrow {
                display: none;
                align-items: center;
                padding-bottom: var(--sketchSpacing3);
                font-size: .7em;
                color: var(--sketchColorTextWizardstep, #b9b9b9);
                transition: all .3s ease-in-out;
            }
            .wizardstep{
                padding-left: var(--sketchSpacing4);
                padding-bottom: var(--sketchSpacing3);
                margin: 0 var(--sketchSpacing6) 0 0 0;
                color: var(--sketchColorTextWizardstep, #b9b9b9);
                display: flex;
                align-items: center;
                border-left: 2px solid transparent;
                transition: all .3s ease-in-out;
            }

            .wizardstep:last-child {
                padding-right: var(--sketchSpacing4);
                padding-bottom: 0;
            }
            .wizardstep.completed,
            .wizardstep.active {
                color: var(--sketchColorTextWizardstepActive, var(--sketchColorText));
                border-left: 2px solid var(--sketchBorderColorWizardStepActive, var(--sketchBackgroundColorSuccess));
            }
            .wizardstep.completed:hover {
                cursor: pointer;
            }
            .slotwrapper {
                display: none;
            }
            .slotwrapper.active {
                display: block;
            }
            .wizardcontent {
                padding-top: var(--sketchSpacing7);
                overflow-x: hidden;
                display: flex;
            }
            .wizardbuttons {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-top: var(--sketchSpacing8);
            }
            .wizardbuttons #finishbutton{
                display: none;
            }
            .firststep #backbutton,
            .firststep #finishbutton{
                display: none;
            }
            .finalstep #nextbutton{
                display: none;
            }
            .finalstep #finishbutton{
                display: block;
            }

            @media (min-width: 768px) {
                .wizardsteps {
                    flex-direction: row;
                    align-items: flex-end;
                }
                .wizardstep{
                    padding-left: var(--sketchSpacing8);
                    padding-right: var(--sketchSpacing8);
                    border-bottom: 2px solid transparent;
                }
                .wizardstep:first-child {
                    padding-left: 0;
                }
                .arrow {
                    display: flex;
                    border-bottom: 2px solid transparent;
                }
                .arrow.active,
                .arrow.completed {
                    color: var(--sketchColorTextWizardstepActive, var(--sketchColorText));
                    border-bottom: 2px solid var(--sketchBorderColorWizardStepActive, var(--sketchBackgroundColorSuccess));
                    border-left: none;
                }
                .wizardstep.completed,
                .wizardstep.active {
                    border-bottom: 2px solid var(--sketchBorderColorWizardStepActive, var(--sketchBackgroundColorSuccess));
                    border-left: none;
                }
                .wizardstep:last-child {
                    padding-bottom: var(--sketchSpacing3);
                }   
                .wizardbuttons {
                    margin-top: var(--sketchSpacing6);
                    display: flex;
                    justify-content: flex-start;
                    margin-top: var(--sketchSpacing8);
                }
                .wizardbuttons sketch-button {
                    margin-right: var(--sketchSpacing6);
                    margin-bottom: var(--sketchSpacing6);
                }
            }
        </style>
        <div class="wizard">
            <div class="wizardsteps">
                ${this._renderWizardSteps()}
            </div>
            <div class="border"></div>
            <div class="wizardcontent">
                ${this._renderSlots()}
            </div>
        </div>`;
    }
}
