import {isBooleanAttributeTrue} from '../utils/index.js';

export class SketchHeading extends HTMLElement {
    constructor() {
        super();
        this.shadow = this.attachShadow({mode: 'open'});
    }

    static get observedAttributes() {
        return ['variant', 'color', 'nomargin'];
    }

    set variant(value) {
        this.setAttribute('variant', value);
    }
    get variant() {
        return this.getAttribute('variant');
    }

    set color(value) {
        this.setAttribute('color', value);
    }
    get color() {
        return this.getAttribute('color');
    }

    get nomargin() {
        return isBooleanAttributeTrue(this.getAttribute('nomargin'));
    }

    set nomargin(value) {
        this.setAttribute('nomargin', value);
    }

    writeTag() {
        return `h${this.variant.split('-')[1]}`;
    }

    connectedCallback() {
        this.render();
    }

    render() {
        this.shadow.innerHTML = `
        <style>
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0;
            color: var(--sketchColorHeading);
            font-family: var(--sketchFontFamilyHeading);
        }
        h1 {
            font-size: var(--sketchFontSizeHeading1);
            margin-bottom: var(--sketchSpacing8);
        }
        h2 {
            font-size: var(--sketchFontSizeHeading2);
            margin-bottom: var(--sketchSpacing7);
        }
        h3 {
            font-size: var(--sketchFontSizeHeading3);
            margin-bottom: var(--sketchSpacing6);
        }
        h4 {
            font-size: var(--sketchFontSizeHeading4);
            font-weight: 100;
            margin-bottom: var(--sketchSpacing3);
        }
        h5 {
            font-size: var(--sketchFontSizeHeading5);
            font-weight: 100;
            margin-bottom: var(--sketchSpacing2);
        }
        h6 {
            font-size: var(--sketchFontSizeHeading6);
            font-weight: 100;
            margin-bottom: var(--sketchSpacing1);
        }
        .primary {
            color: var(--sketchColorPrimary);
        }
        .secondary {
            color: var(--sketchColorSecondary);
        }
        .tertiary {
            color: var(--sketchColorTertiary);
        }
        .nomargin {
            margin-bottom: 0;
        }
        </style>
        <${this.writeTag()} class="${this.color} ${
            this.nomargin ? 'nomargin' : ''
        }">
            <slot></slot>
        </${this.writeTag()}>`;
    }
}
