import fallbackImage from '../assets/img/image-not-found.png';
import {
    isBooleanAttributeTrue,
    isIntersecting,
    getImageNameFromUrl,
} from '../utils/index.js';

export class SketchImage extends HTMLElement {
    isLoadingCustomFallback = false;

    safeSetAttribute = (name, value) => {
        if (this.getAttribute(name) !== value) {
            this.setAttribute(name, value);
        }
    };

    static get observedAttributes() {
        return [
            'src',
            'srcmd',
            'srclg',
            'srcxl',
            'alt',
            'fallback',
            'showloading',
            'aspectratio',
        ];
    }

    set src(value) {
        this.safeSetAttribute('src', value);
        if (this.intersecting) {
            this.loadImage();
        }
    }

    get src() {
        return this.getAttribute('src');
    }

    set srcmd(value) {
        this.safeSetAttribute('srcmd', value);
        if (this.intersecting) {
            this.loadImage();
        }
    }

    get srcmd() {
        return this.getAttribute('srcmd');
    }

    set srclg(value) {
        this.safeSetAttribute('srclg', value);
        if (this.intersecting) {
            this.loadImage();
        }
    }

    get srclg() {
        return this.getAttribute('srclg');
    }

    set srcxl(value) {
        this.safeSetAttribute('srcxl', value);
        if (this.intersecting) {
            this.loadImage();
        }
    }

    get srcxl() {
        return this.getAttribute('srcxl');
    }

    set fallback(value) {
        this.safeSetAttribute('fallback', value);
    }

    get fallback() {
        let imgsrc = fallbackImage;
        if (
            this.getAttribute('fallback') !== undefined &&
            this.getAttribute('fallback') !== null
        ) {
            imgsrc = this.getAttribute('fallback');
        }
        return imgsrc;
    }

    set alt(value) {
        this.safeSetAttribute('alt', value);
        this.shadowImage.alt = value;
    }

    get alt() {
        return this.getAttribute('alt');
    }

    set showloading(value) {
        this.safeSetAttribute('showloading', value);
        if (isBooleanAttributeTrue(value)) {
            const loadingbox = document.createElement('sketch-skeletonbox');
            loadingbox.setAttribute('style', 'height:100%;');
            this.shadowPlaceholder.appendChild(loadingbox);
        }
    }

    get showloading() {
        return isBooleanAttributeTrue(this.getAttribute('showloading'));
    }

    get intersecting() {
        return this.hasAttribute('intersecting');
    }

    set aspectratio(value) {
        this.safeSetAttribute('aspectratio', value);
        this.shadowPlaceholder.setAttribute('style', `aspect-ratio: ${value};`);
    }

    get aspectratio() {
        return this.getAttribute('aspectratio') || null;
    }

    set intersecting(v) {}

    constructor() {
        super();
        this.attachShadow({mode: 'open'});
        this.render();
        this.shadowImage = this.shadowRoot.getElementById('image');

        this.shadowImage.onload = this.onLoad;
        this.shadowImage.onerror = this.onError;
        this.shadowPlaceholder = this.shadowRoot.getElementById('placeholder');
        this.loadingbox = this.shadowRoot.querySelector('sketch-skeletonbox');
    }

    connectedCallback() {
        this.setAttribute('role', 'presentation');
        this.applyImageAttributes();
        this.src = this.getAttribute('src');
        this.alt = this.getAttribute('alt');
        this.placeholder = this.getAttribute('placeholder');
        if ('IntersectionObserver' in window) {
            this.initIntersectionObserver();
        } else {
            this.loadImage();
        }
    }

    attributeChangedCallback(name, oldVal, newVal) {
        this[name] = newVal;
    }

    disconnectedCallback() {
        this.disconnectObserver();
    }

    hasMultipleSources = () => this.srcmd || this.srclg || this.srcxl;

    /**
     * Sets the intersecting attribute and reload styles if the polyfill is at play.
     */
    loadImage = () => {
        this.setAttribute('intersecting', '');
        this.disconnectObserver();
        if (!this.hasMultipleSources()) {
            this.shadowImage.src = this.src;
        } else {
            this.shadowRoot.removeChild(this.shadowImage);
            this.shadowImage = null;
            const picture = document.createElement('picture');
            picture.setAttribute('id', 'picture');
            picture.setAttribute('aria-hidden', 'true');
            if (this.srcxl) {
                const source = document.createElement('source');
                source.setAttribute('srcset', this.srcxl);
                source.setAttribute('media', '(min-width: 1200px)');
                picture.appendChild(source);
            }
            if (this.srclg) {
                const source = document.createElement('source');
                source.setAttribute('srcset', this.srclg);
                source.setAttribute('media', '(min-width: 992px)');
                picture.appendChild(source);
            }
            if (this.srcmd) {
                const source = document.createElement('source');
                source.setAttribute('srcset', this.srcmd);
                source.setAttribute('media', '(min-width: 768px)');
                picture.appendChild(source);
            }
            if (this.srcsm) {
                const source = document.createElement('source');
                source.setAttribute('srcset', this.srcsm);
                source.setAttribute('media', '(min-width: 576px)');
                picture.appendChild(source);
            }

            const img = document.createElement('img');
            img.setAttribute('src', this.src);
            img.setAttribute('alt', this.alt);
            img.setAttribute('id', 'image');
            picture.appendChild(img);
            img.onload = this.onLoad;
            img.onerror = this.onError;
            this.shadowRoot.appendChild(picture);
            this.shadowPicture = picture;
        }
    };

    onLoad = (event) => {
        if (this.showloading && this.shadowPlaceholder) {
            this.shadowRoot.removeChild(this.shadowPlaceholder);
            this.shadowPlaceholder = null;
        }
        this.dispatchEvent(
            new CustomEvent('loadend', {detail: {success: true}})
        );
        if (this.shadowImage) {
            this.shadowImage.removeAttribute('aria-hidden');
        }
        if (this.shadowPicture) {
            this.shadowPicture.removeAttribute('aria-hidden');
        }
        this.disconnectObserver();
    };

    onError = () => {
        this.dispatchEvent(
            new CustomEvent('loadend', {detail: {success: false}})
        );
        if (this.fallback) {
            if (!this.isLoadingCustomFallback) {
                this.setFallbackImage(this.fallback);
                this.isLoadingCustomFallback = true;
            } else {
                this.setFallbackImage(this.fallback);
            }
        }
    };

    /**
     * Gets the currently active <source> element from the <picture>
     *
     * @param {*} pictureElement reference to the <picture> element
     * @param {*} srcString source of the image currently being displayed
     * @returns <source> element
     */
    getCurrentSourceElementBySrcString = (pictureElement, srcString) => {
        let srcElement = null;
        const srcImageName = getImageNameFromUrl(srcString);
        pictureElement.querySelectorAll('source').forEach((sourceElement) => {
            if (getImageNameFromUrl(sourceElement.srcset) === srcImageName) {
                srcElement = sourceElement;
            }
        });
        return srcElement;
    };

    setFallbackImage = (fallbackImageSource) => {
        if (this.shadowImage) {
            this.shadowImage.src = fallbackImageSource;
        }
        if (this.shadowPicture) {
            const imgElement = this.shadowPicture.querySelector('img');
            const currentSourceElement =
                this.getCurrentSourceElementBySrcString(
                    this.shadowPicture,
                    imgElement.currentSrc
                );
            if (currentSourceElement) {
                currentSourceElement.srcset = fallbackImageSource;
            }
        }
    };

    /**
     * Sets the `intersecting` property when the element is on screen.
     * @param entries
     */
    observerCallback = (entries) => {
        if (entries.some(isIntersecting)) {
            this.loadImage();
        }
    };

    /**
     * Initializes the IntersectionObserver when the element instantiates.
     */
    initIntersectionObserver = () => {
        if (this.observer) {
            return;
        }
        const rootMargin = '10px';
        this.observer = new IntersectionObserver(this.observerCallback, {
            rootMargin,
        });
        this.observer.observe(this);
    };

    /**
     * Disconnects and unloads the IntersectionObserver.
     */
    disconnectObserver = () => {
        if (!this.observer) {
            return;
        }
        this.observer.disconnect();
        this.observer = null;
        delete this.observer;
    };

    applyImageAttributes = () => {
        const properties = ['height', 'width'];
        properties.forEach((property) => {
            if (this.getAttribute(property)) {
                this.shadowImage.setAttribute(
                    property,
                    this.getAttribute(property)
                );
            } else {
                this.shadowImage.setAttribute(property, '100%');
            }
        });
    };

    render() {
        this.shadowRoot.innerHTML = `
        <style>
            :host {
                display: block;
            }

            #image,
            picture,
            #placeholder {
                transition: opacity 1.3s ease;
                object-fit: contain; 
                width: 100%;
                height: ${this.getAttribute('height') || '100%'};
            }

            :host #placeholder:not([aria-hidden="true"]) ::slotted(*),
            :host #image:not([aria-hidden="true"]),
            :host #picture:not([aria-hidden="true"]) {
                opacity: 1;
            }

            :host #image,
            :host #picture,
            :host #placeholder[aria-hidden="true"] ::slotted(*) {
                opacity: 0;
            }
        </style>
        <div id="placeholder" aria-hidden="false">
        </div>
        <img id="image" aria-hidden="true" alt="${this.alt}"/>
      `;
    }
}
