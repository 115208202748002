import {isBooleanAttributeTrue} from '../utils/index.js';

export class SketchHeading extends HTMLElement {
    constructor() {
        super();
        this.shadow = this.attachShadow({mode: 'open'});
    }

    static get observedAttributes() {
        return ['variant', 'color', 'nomargin'];
    }

    set variant(value) {
        this.setAttribute('variant', value);
    }
    get variant() {
        return this.getAttribute('variant');
    }

    set color(value) {
        this.setAttribute('color', value);
    }
    get color() {
        return this.getAttribute('color');
    }

    get nomargin() {
        return isBooleanAttributeTrue(this.getAttribute('nomargin'));
    }

    set nomargin(value) {
        this.setAttribute('nomargin', value);
    }

    writeTag() {
        return `h${this.variant.split('-')[1]}`;
    }

    connectedCallback() {
        this.render();
    }

    render() {
        this.shadow.innerHTML = `
        <style>
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0;
            color: var(--sketchColorHeading);
            font-family: var(--sketchFontFamilyHeading);
        }
        h1 {
            margin-bottom: var(--sketchSpacing8);
            font-size: var(--sketchFontSizeHeading1, 2rem);
            font-weight: var(--sketchFontWeightHeading1, 700);
            font-style: var(--sketchFontStyleHeading1, normal);
        }
        h2 {
            margin-bottom: var(--sketchSpacing7);
            font-size: var(--sketchFontSizeHeading2, 1.8rem);
            font-weight: var(--sketchFontWeightHeading2, 700);
            font-style: var(--sketchFontStyleHeading2, normal);
        }
        h3 {
            margin-bottom: var(--sketchSpacing6);
            font-size: var(--sketchFontSizeHeading3, 1.6rem);
            font-weight: var(--sketchFontWeightHeading3, 700);
            font-style: var(--sketchFontStyleHeading3, normal);
        }
        h4 {
            margin-bottom: var(--sketchSpacing3);
            font-size: var(--sketchFontSizeHeading4, 1.5rem);
            font-weight: var(--sketchFontWeightHeading4, 400);
            font-style: var(--sketchFontStyleHeading4, normal);
        }
        h5 {
            margin-bottom: var(--sketchSpacing2);
            font-size: var(--sketchFontSizeHeading5, 1.3rem);
            font-weight: var(--sketchFontWeightHeading5, 400);
            font-style: var(--sketchFontStyleHeading5, normal);            
        }
        h6 {
            margin-bottom: var(--sketchSpacing1);
            font-size: var(--sketchFontSizeHeading6, 1.1rem);
            font-weight: var(--sketchFontWeightHeading6, 400);
            font-style: var(--sketchFontStyleHeading6, normal);
        }
        .primary {
            color: var(--sketchColorPrimary);
        }
        .secondary {
            color: var(--sketchColorSecondary);
        }
        .tertiary {
            color: var(--sketchColorTertiary);
        }
        .nomargin {
            margin-bottom: 0;
        }
        </style>
        <${this.writeTag()} class="${this.color} ${
            this.nomargin ? 'nomargin' : ''
        }">
            <slot></slot>
        </${this.writeTag()}>`;
    }
}
