export class SketchLink extends HTMLElement {
    static get observedAttributes() {
        return ['href', 'target'];
    }

    constructor() {
        super();
        this.shadow = this.attachShadow({mode: 'open', delegatesFocus: true});
    }

    get href() {
        return this.getAttribute('href');
    }

    set href(value) {
        this.setAttribute('href', value);
    }

    get target() {
        return this.getAttribute('target');
    }

    set target(value) {
        this.setAttribute('target', value);
    }

    connectedCallback() {
        this.render();
    }

    // attributeChangedCallback(property, oldValue, newValue) {
    //     this.render();
    // }

    render() {
        this.shadow.innerHTML = `
        <style>
        a,
        a:visited {
            color: var(--sketchColorLink);
            text-decoration: var(--sketchLinkDecoration);
            transition: all .3s ease;
        }
        a:hover {
            color: var(--sketchColorLinkHover);
            text-decoration: var(--sketchLinkDecoration);
        }
        </style>
        <a href="${this.href}" ${
            this.target ? `target="${this.target}"` : ''
        }><slot></slot></a>`;
    }
}
