import {RRule} from 'rrule';
import {translations} from './translations.js';
import {isBooleanAttributeTrue} from '../../utils/index.js';

const PATTERNSECTIONINDEX = '1';
const DAYSECTIONINDEX = '2';

const YEARLYDAYSECTIONINDEX = '1';
const YEARLYMONTHSECTIONINDEX = '2';
const YEARLYPATTERNSECTIONINDEX = '3';

const ENDUNTILSECTIONINDEX = '1';
const ENDCOUNTSECTIONINDEX = '2';

const incrementDate = (date, increment = 1) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + increment);
    return newDate;
};

const getTranslationForKey = (language, key) => {
    if (translations) {
        if (translations[language]) {
            return translations[language][key];
        }
    }
    return key;
};

export class SketchRepeatRule extends HTMLElement {
    /* for form elements */
    static formAssociated = true;

    $shadowRoot;

    $internals;

    $fallbackmode = false;

    _ruleOptions;

    _monthActiveSection;

    _yearActiveSection;

    _endActiveSection;

    static get observedAttributes() {
        return [
            'labelStartDate',
            'labelEndDateButton',
            'labelEndCountButton',
            'labelFrequencyDays',
            'labelPattern',
            'labelFrequencyMonths',
            'labelFrequencyWeeks',
            'labelFrequencyYears',
            'labelInterval',
            'labelMonday',
            'labelTuesday',
            'labelWednesday',
            'labelThursday',
            'labelFriday',
            'labelSaturday',
            'labelSunday',
            'labelWeekday',
            'labelWeekendday',
            'labelOnce',
            'labelDay',
            'labelJanuary',
            'labelFebruary',
            'labelMarch',
            'labelApril',
            'labelMay',
            'labelJune',
            'labelJuly',
            'labelAugust',
            'labelSeptember',
            'labelOctober',
            'labelNovember',
            'labelDecember',
            'labelFirst',
            'labelSecond',
            'labelThird',
            'labelFourth',
            'labelLast',
            'labelMonthDays',
            'labelOnWeekdays',
            'labelRepeatCountSuffix',
            'labelMonthDays',
            'labelInMonths',
            'labelOfMonth',
            'messageStartdateRequired',
            'messageEnddateRequired',
            'messageRepeatcountRequired',
            'messageIntervalRequired',
            'value',
            'name',
            'label',
            'language',
            'fieldId',
            'disabled',
            'subgrid',
            'minDate',
            'maxDate',
            'checkboxdirection',
        ];
    }

    constructor() {
        super();
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: false,
        });
        try {
            this.$internals = this.attachInternals();
        } catch (error) {
            this.$fallbackmode = true;
        }
        this._monthActiveSection = '1';
        this._yearActiveSection = '1';
        this._endActiveSection = ENDUNTILSECTIONINDEX;
        this._ruleOptions = {
            interval: '1',
            freq: RRule.WEEKLY,
            dtstart: new Date(
                `${new Date().toISOString().slice(0, 10)}T00:00:00.000Z`
            ),
            until: new Date(
                `${new Date().toISOString().slice(0, 10)}T00:00:00.000Z`
            ),
            count: 1,
            weekly: {
                byweekday: [], // getDay starts on sunday
            },
            monthly: {
                bysetpos: 1,
                byweekday: [1],
                bymonthday: [],
            },
            yearly: {
                bymonthday: 1,
                bymonth: [],
                bysetpos: 1,
                byweekday: [0],
            },
        };
    }

    get language() {
        return this.getAttribute('language') || 'E';
    }

    set language(value) {
        this.setAttribute('language', value);
    }

    get checkboxdirection() {
        return this.getAttribute('checkboxdirection') || 'horizontal';
    }

    set checkboxdirection(value) {
        this.setAttribute('checkboxdirection', value);
    }

    get labelStartDate() {
        return (
            this.getAttribute('labelStartDate') ||
            getTranslationForKey(this.language, 'labelStartDate')
        );
    }

    set labelStartDate(value) {
        this.setAttribute('labelStartDate', value);
    }

    get labelEndDateButton() {
        return (
            this.getAttribute('labelEndDateButton') ||
            getTranslationForKey(this.language, 'labelEndDateButton')
        );
    }

    set labelEndDateButton(value) {
        this.setAttribute('labelEndDateButton', value);
    }

    get labelMonthDays() {
        return (
            this.getAttribute('labelMonthDays') ||
            getTranslationForKey(this.language, 'labelMonthDays')
        );
    }

    set labelMonthDays(value) {
        this.setAttribute('labelMonthDays', value);
    }

    get labelInMonths() {
        return (
            this.getAttribute('labelInMonths') ||
            getTranslationForKey(this.language, 'labelInMonths')
        );
    }

    set labelInMonths(value) {
        this.setAttribute('labelInMonths', value);
    }

    get labelOnce() {
        return (
            this.getAttribute('labelOnce') ||
            getTranslationForKey(this.language, 'labelOnce')
        );
    }

    set labelOnce(value) {
        this.setAttribute('labelOnce', value);
    }

    get labelEndCountButton() {
        return (
            this.getAttribute('labelEndCountButton') ||
            getTranslationForKey(this.language, 'labelEndCountButton')
        );
    }

    set labelEndCountButton(value) {
        this.setAttribute('labelEndCountButton', value);
    }

    get labelFrequencyDays() {
        return (
            this.getAttribute('labelFrequencyDays') ||
            getTranslationForKey(this.language, 'labelFrequencyDays')
        );
    }

    set labelFrequencyDays(value) {
        this.setAttribute('labelFrequencyDays', value);
    }

    get labelPattern() {
        return (
            this.getAttribute('labelPattern') ||
            getTranslationForKey(this.language, 'labelPattern')
        );
    }

    set labelPattern(value) {
        this.setAttribute('labelPattern', value);
    }

    get labelFrequencyWeeks() {
        return (
            this.getAttribute('labelFrequencyWeeks') ||
            getTranslationForKey(this.language, 'labelFrequencyWeeks')
        );
    }

    set labelFrequencyWeeks(value) {
        this.setAttribute('labelFrequencyWeeks', value);
    }

    get labelFrequencyMonths() {
        return (
            this.getAttribute('labelFrequencyMonths') ||
            getTranslationForKey(this.language, 'labelFrequencyMonths')
        );
    }

    set labelFrequencyMonths(value) {
        this.setAttribute('labelFrequencyMonths', value);
    }

    get labelFrequencyYears() {
        return (
            this.getAttribute('labelFrequencyYears') ||
            getTranslationForKey(this.language, 'labelFrequencyYears')
        );
    }

    set labelFrequencyYears(value) {
        this.setAttribute('labelFrequencyYears', value);
    }

    get labelInterval() {
        return (
            this.getAttribute('labelInterval') ||
            getTranslationForKey(this.language, 'labelInterval')
        );
    }

    set labelInterval(value) {
        this.setAttribute('labelInterval', value);
    }

    get labelOnWeekdays() {
        return (
            this.getAttribute('labelOnWeekdays') ||
            getTranslationForKey(this.language, 'labelOnWeekdays')
        );
    }

    set labelOnWeekdays(value) {
        this.setAttribute('labelOnWeekdays', value);
    }

    get labelMonday() {
        return (
            this.getAttribute('labelMonday') ||
            getTranslationForKey(this.language, 'labelMonday')
        );
    }

    set labelMonday(value) {
        this.setAttribute('labelMonday', value);
    }

    get labelTuesday() {
        return (
            this.getAttribute('labelTuesday') ||
            getTranslationForKey(this.language, 'labelTuesday')
        );
    }

    set labelTuesday(value) {
        this.setAttribute('labelTuesday', value);
    }

    get labelWednesday() {
        return (
            this.getAttribute('labelWednesday') ||
            getTranslationForKey(this.language, 'labelWednesday')
        );
    }

    set labelWednesday(value) {
        this.setAttribute('labelWednesday', value);
    }

    get labelThursday() {
        return (
            this.getAttribute('labelThursday') ||
            getTranslationForKey(this.language, 'labelThursday')
        );
    }

    set labelThursday(value) {
        this.setAttribute('labelThursday', value);
    }

    get labelFriday() {
        return (
            this.getAttribute('labelFriday') ||
            getTranslationForKey(this.language, 'labelFriday')
        );
    }

    set labelFriday(value) {
        this.setAttribute('labelFriday', value);
    }

    get labelSaturday() {
        return (
            this.getAttribute('labelSaturday') ||
            getTranslationForKey(this.language, 'labelSaturday')
        );
    }

    set labelSaturday(value) {
        this.setAttribute('labelSaturday', value);
    }

    get labelSunday() {
        return (
            this.getAttribute('labelSunday') ||
            getTranslationForKey(this.language, 'labelSunday')
        );
    }

    set labelSunday(value) {
        this.setAttribute('labelSunday', value);
    }

    get labelWeekday() {
        return (
            this.getAttribute('labelWeekday') ||
            getTranslationForKey(this.language, 'labelWeekday')
        );
    }

    set labelWeekday(value) {
        this.setAttribute('labelWeekday', value);
    }

    get labelOfMonth() {
        return (
            this.getAttribute('labelOfMonth') ||
            getTranslationForKey(this.language, 'labelOfMonth')
        );
    }

    set labelOfMonth(value) {
        this.setAttribute('labelOfMonth', value);
    }

    get labelWeekendday() {
        return (
            this.getAttribute('labelWeekendday') ||
            getTranslationForKey(this.language, 'labelWeekendday')
        );
    }

    set labelWeekendday(value) {
        this.setAttribute('labelWeekendday', value);
    }

    get labelDay() {
        return (
            this.getAttribute('labelDay') ||
            getTranslationForKey(this.language, 'labelDay')
        );
    }

    set labelDay(value) {
        this.setAttribute('labelDay', value);
    }

    get labelJanuary() {
        return (
            this.getAttribute('labelJanuary') ||
            getTranslationForKey(this.language, 'labelJanuary')
        );
    }

    set labelJanuary(value) {
        this.setAttribute('labelJanuary', value);
    }

    get labelFebruary() {
        return (
            this.getAttribute('labelFebruary') ||
            getTranslationForKey(this.language, 'labelFebruary')
        );
    }

    set labelFebruary(value) {
        this.setAttribute('labelFebruary', value);
    }

    get labelMarch() {
        return (
            this.getAttribute('labelMarch') ||
            getTranslationForKey(this.language, 'labelMarch')
        );
    }

    set labelMarch(value) {
        this.setAttribute('labelMarch', value);
    }

    get labelApril() {
        return (
            this.getAttribute('labelApril') ||
            getTranslationForKey(this.language, 'labelApril')
        );
    }

    set labelApril(value) {
        this.setAttribute('labelApril', value);
    }

    get labelMay() {
        return (
            this.getAttribute('labelMay') ||
            getTranslationForKey(this.language, 'labelMay')
        );
    }

    set labelMay(value) {
        this.setAttribute('labelMay', value);
    }

    get labelJune() {
        return (
            this.getAttribute('labelJune') ||
            getTranslationForKey(this.language, 'labelJune')
        );
    }

    set labelJune(value) {
        this.setAttribute('labelJune', value);
    }

    get labelJuly() {
        return (
            this.getAttribute('labelJuly') ||
            getTranslationForKey(this.language, 'labelJuly')
        );
    }

    set labelJuly(value) {
        this.setAttribute('labelJuly', value);
    }

    get labelAugust() {
        return (
            this.getAttribute('labelAugust') ||
            getTranslationForKey(this.language, 'labelAugust')
        );
    }

    set labelAugust(value) {
        this.setAttribute('labelAugust', value);
    }

    get labelSeptember() {
        return (
            this.getAttribute('labelSeptember') ||
            getTranslationForKey(this.language, 'labelSeptember')
        );
    }

    set labelSeptember(value) {
        this.setAttribute('labelSeptember', value);
    }

    get labelOctober() {
        return (
            this.getAttribute('labelOctober') ||
            getTranslationForKey(this.language, 'labelOctober')
        );
    }

    set labelOctober(value) {
        this.setAttribute('labelOctober', value);
    }

    get labelNovember() {
        return (
            this.getAttribute('labelNovember') ||
            getTranslationForKey(this.language, 'labelNovember')
        );
    }

    set labelNovember(value) {
        this.setAttribute('labelNovember', value);
    }

    get labelDecember() {
        return (
            this.getAttribute('labelDecember') ||
            getTranslationForKey(this.language, 'labelDecember')
        );
    }

    set labelDecember(value) {
        this.setAttribute('labelDecember', value);
    }

    get labelFirst() {
        return (
            this.getAttribute('labelFirst') ||
            getTranslationForKey(this.language, 'labelFirst')
        );
    }

    set labelFirst(value) {
        this.setAttribute('labelFirst', value);
    }

    get labelSecond() {
        return (
            this.getAttribute('labelSecond') ||
            getTranslationForKey(this.language, 'labelSecond')
        );
    }

    set labelSecond(value) {
        this.setAttribute('labelSecond', value);
    }

    get labelThird() {
        return (
            this.getAttribute('labelThird') ||
            getTranslationForKey(this.language, 'labelThird')
        );
    }

    set labelThird(value) {
        this.setAttribute('labelThird', value);
    }

    get labelFourth() {
        return (
            this.getAttribute('labelFourth') ||
            getTranslationForKey(this.language, 'labelFourth')
        );
    }

    set labelFourth(value) {
        this.setAttribute('labelFourth', value);
    }

    get labelLast() {
        return (
            this.getAttribute('labelLast') ||
            getTranslationForKey(this.language, 'labelLast')
        );
    }

    set labelLast(value) {
        this.setAttribute('labelLast', value);
    }

    get labelRepeatCountSuffix() {
        return (
            this.getAttribute('labelRepeatCountSuffix') ||
            getTranslationForKey(this.language, 'labelRepeatCountSuffix')
        );
    }

    set labelRepeatCountSuffix(value) {
        this.setAttribute('labelRepeatCountSuffix', value);
    }

    get messageStartdateRequired() {
        return (
            this.getAttribute('messageStartdateRequired') ||
            getTranslationForKey(this.language, 'messageStartdateRequired')
        );
    }

    set messageStartdateRequired(value) {
        this.setAttribute('messageStartdateRequired', value);
    }

    get messageEnddateRequired() {
        return (
            this.getAttribute('messageEnddateRequired') ||
            getTranslationForKey(this.language, 'messageEnddateRequired')
        );
    }

    set messageEnddateRequired(value) {
        this.setAttribute('messageEnddateRequired', value);
    }

    get messageRepeatcountRequired() {
        return (
            this.getAttribute('messageRepeatcountRequired') ||
            getTranslationForKey(this.language, 'messageRepeatcountRequired')
        );
    }

    set messageRepeatcountRequired(value) {
        this.setAttribute('messageRepeatcountRequired', value);
    }

    get messageIntervalRequired() {
        return (
            this.getAttribute('messageIntervalRequired') ||
            getTranslationForKey(this.language, 'messageIntervalRequired')
        );
    }

    set messageIntervalRequired(value) {
        this.setAttribute('messageIntervalRequired', value);
    }

    get value() {
        return this.getAttribute('value') || '';
    }

    set value(val) {
        this.setAttribute('value', val);
    }

    get name() {
        return this.getAttribute('name');
    }

    set name(value) {
        this.setAttribute('name', value);
    }

    get label() {
        return this.getAttribute('label');
    }

    set label(value) {
        this.setAttribute('label', value);
    }

    get fieldId() {
        return this.getAttribute('fieldId');
    }

    set fieldId(value) {
        this.setAttribute('fieldId', value);
    }

    get minDate() {
        return this.getAttribute('minDate');
    }

    set minDate(value) {
        this.setAttribute('minDate', value);
    }

    get maxDate() {
        return this.getAttribute('maxDate');
    }

    set maxDate(value) {
        this.setAttribute('maxDate', value);
    }

    get disabled() {
        return isBooleanAttributeTrue(this.getAttribute('disabled'));
    }

    set disabled(value) {
        this.setAttribute('disabled', value);
    }

    get subgrid() {
        return isBooleanAttributeTrue(this.getAttribute('subgrid'));
    }

    set subgrid(value) {
        this.setAttribute('subgrid', value);
    }

    get fallbackmode() {
        return isBooleanAttributeTrue(this.getAttribute('fallbackmode'));
    }

    set fallbackmode(value) {
        this.setAttribute('fallbackmode', value);
    }

    connectedCallback() {
        if (this.$fallbackmode) {
            this.fallbackmode = true;
        }
        this.render();
        if (!this.value) {
            this._updateValue();
        }
        if (!this.fallbackmode) {
            this.$internals.setFormValue(this.value);
        }

        this._bindEvents();
    }

    _bindEvents() {
        if (!this.disabled) {
            this.$shadowRoot
                .querySelector('#startdate')
                .addEventListener('change', this._handleStartDateChange);
            this.$shadowRoot
                .querySelector('#interval')
                .addEventListener('change', this._handleIntervalChange);
            this.$shadowRoot
                .querySelector('#frequency')
                .addEventListener('change', this._handleFrequencyChange);
            this.$shadowRoot
                .querySelector('#weekdays')
                ?.addEventListener('change', this._handleWeekdaysChange);
            this.$shadowRoot
                .querySelectorAll('.monthgrid > .daybutton')
                .forEach((dayButton) => {
                    dayButton.addEventListener(
                        'click',
                        this._handleMonthDayChange
                    );
                });
            this.$shadowRoot
                .querySelector('#monthtabs')
                ?.addEventListener('tabchange', this._handleMonthTabChange);
            this.$shadowRoot
                .querySelector('#monthlysetpos')
                ?.addEventListener('change', this._handleMonthSetposChange);
            this.$shadowRoot
                .querySelector('#weekdayselect')
                ?.addEventListener('change', this._handleWeekdaySelectChange);
            this.$shadowRoot
                .querySelector('#yeartabs')
                ?.addEventListener('tabchange', this._handleYearTabChange);
            this.$shadowRoot
                .querySelector('#yearlydayinput')
                ?.addEventListener('change', this._handleYearlyDayInputChange);
            this.$shadowRoot
                .querySelectorAll('#yearlymonthselect')
                .forEach((select) => {
                    select?.addEventListener(
                        'change',
                        this._handleYearlyMonthSelectChange
                    );
                });
            this.$shadowRoot
                .querySelector('#yearlymonthcheckboxgroup')
                ?.addEventListener(
                    'change',
                    this._handleYearlyMonthCheckboxgroupChange
                );
            // yearly
            this.$shadowRoot
                .querySelector('#yearsetpos')
                ?.addEventListener('change', this._handleYearlySetPosChange);
            this.$shadowRoot
                .querySelector('#yearlyweekdayselect')
                ?.addEventListener(
                    'change',
                    this._handleYearlyWeekdaySelectChange
                );
            this.$shadowRoot
                .querySelector('#enddate')
                ?.addEventListener('change', this._handleEndDateChange);
            this.$shadowRoot
                .querySelector('#count')
                ?.addEventListener('change', this._handleCountChange);
            this.$shadowRoot
                .querySelector('#stoptabs')
                ?.addEventListener('tabchange', this._handleEndTabChange);
        }
    }

    _unbindEvents() {
        this.$shadowRoot
            .querySelector('#startdate')
            ?.removeEventListener('change', this._handleStartDateChange);
        this.$shadowRoot
            .querySelector('#interval')
            ?.removeEventListener('change', this._handleIntervalChange);
        this.$shadowRoot
            .querySelector('#frequency')
            ?.removeEventListener('change', this._handleFrequencyChange);
        this.$shadowRoot
            .querySelector('#weekdays')
            ?.removeEventListener('change', this._handleWeekdaysChange);
        this.$shadowRoot
            .querySelectorAll('.monthgrid > .daybutton')
            .forEach((dayButton) => {
                dayButton.removeEventListener(
                    'click',
                    this._handleMonthDayChange
                );
            });
        this.$shadowRoot
            .querySelector('#monthtabs')
            ?.removeEventListener('tabchange', this._handleMonthTabChange);
        this.$shadowRoot
            .querySelector('#monthlysetpos')
            ?.removeEventListener('change', this._handleMonthSetposChange);
        this.$shadowRoot
            .querySelector('#weekdayselect')
            ?.removeEventListener('change', this._handleWeekdaySelectChange);
        this.$shadowRoot
            .querySelector('#yeartabs')
            ?.removeEventListener('tabchange', this._handleYearTabChange);
        this.$shadowRoot
            .querySelector('#yearlydayinput')
            ?.removeEventListener('change', this._handleYearlyDayInputChange);
        this.$shadowRoot
            .querySelectorAll('#yearlymonthselect')
            .forEach((select) => {
                select?.removeEventListener(
                    'change',
                    this._handleYearlyMonthSelectChange
                );
            });
        this.$shadowRoot
            .querySelector('#yearlymonthcheckboxgroup')
            ?.removeEventListener(
                'change',
                this._handleYearlyMonthCheckboxgroupChange
            );
        // yearly events
        this.$shadowRoot
            .querySelector('#yearsetpos')
            ?.removeEventListener('change', this._handleYearlySetPosChange);
        this.$shadowRoot
            .querySelector('#yearlyweekdayselect')
            ?.removeEventListener(
                'change',
                this._handleYearlyWeekdaySelectChange
            );
        this.$shadowRoot
            .querySelector('#enddate')
            ?.removeEventListener('change', this._handleEndDateChange);
        this.$shadowRoot
            .querySelector('#count')
            ?.removeEventListener('change', this._handleCountChange);
        this.$shadowRoot
            .querySelector('#stoptabs')
            ?.removeEventListener('tabchange', this._handleEndTabChange);
    }

    _updateValue = () => {
        if (this._validate()) {
            const rruleString = this._calculateRRule().toString();
            const valueParts = rruleString.split('\n');
            let reformatted = `${valueParts[1]}${valueParts[0].replace('DTSTART:', ';DTSTART=')}`;
            reformatted = reformatted.replace('RRULE:', '');
            if (!this.fallbackmode) {
                this.$internals.setFormValue(reformatted);
            }
            this.dispatchEvent(
                new CustomEvent('change', {
                    bubbles: true,
                    detail: {
                        value: reformatted,
                    },
                })
            );
            this.value = reformatted;
        }
    };

    _validate = () => {
        let isValid = true;
        const validityFlags = {valueMissing: false};
        let validationMessage = '';
        let messageAnchor = null;
        if (this._ruleOptions.interval < 1 && isValid) {
            isValid = false;
            validationMessage = this.messageIntervalRequired;
            validityFlags.valueMissing = true;
            messageAnchor = this.$shadowRoot.querySelector('.intervalanchor');
        }
        if (!this._ruleOptions.dtstart && isValid) {
            isValid = false;
            validationMessage = this.messageStartdateRequired;
            validityFlags.valueMissing = true;
            messageAnchor = this.$shadowRoot.querySelector('.startdateanchor');
        }
        if (
            this._endActiveSection === ENDUNTILSECTIONINDEX &&
            !this._ruleOptions.until &&
            isValid
        ) {
            // end date tab is selected and end date is not set
            isValid = false;
            validationMessage = this.messageEnddateRequired;
            validityFlags.valueMissing = true;
            messageAnchor = this.$shadowRoot.querySelector('.endwrapper');
        }
        if (
            this._endActiveSection === ENDCOUNTSECTIONINDEX &&
            this._ruleOptions.count < 1 &&
            isValid
        ) {
            // end count tab is selected and count is incorrect
            isValid = false;
            validationMessage = this.messageRepeatcountRequired;
            validityFlags.valueMissing = true;
            messageAnchor = this.$shadowRoot.querySelector('.endwrapper');
        }
        if (!this.fallbackmode) {
            if (!isValid) {
                this.$internals.setValidity(
                    validityFlags,
                    validationMessage,
                    messageAnchor
                );
            } else {
                this.$internals.setValidity({});
            }
        }
        return isValid;
    };

    _handleStartDateChange = (event) => {
        this._ruleOptions.dtstart =
            event.detail.value !== '' ? new Date(event.detail.value) : null;
        if (this._ruleOptions.freq === RRule.WEEKLY) {
            this._ruleOptions.weekly.byweekday = [
                this._ruleOptions.dtstart.getDay() - 1,
            ];
        }
        if (this._ruleOptions.freq === RRule.MONTHLY) {
            if (
                this._monthActiveSection === DAYSECTIONINDEX &&
                this._ruleOptions.monthly.bymonthday &&
                this._ruleOptions.monthly.bymonthday.length <= 1
            ) {
                this._ruleOptions.monthly.bymonthday = [
                    `${this._ruleOptions.dtstart.getDate()}`,
                ];
            }
        }
        if (
            this._ruleOptions.freq === RRule.YEARLY &&
            this._yearActiveSection === YEARLYMONTHSECTIONINDEX
        ) {
            if (
                this._ruleOptions.yearly.bymonth &&
                this._ruleOptions.yearly.bymonth.length <= 1
            ) {
                this._ruleOptions.yearly.bymonth = [
                    this._ruleOptions.dtstart.getMonth() + 1,
                ];
            }
        }
        this._updateValue();
    };

    _handleIntervalChange = (event) => {
        this._ruleOptions.interval = event.detail.value;
        this._updateValue();
    };

    _handleFrequencyChange = (event) => {
        this._ruleOptions.freq = Number(event.detail.value);
        this._updateValue();
    };

    _handleWeekdaysChange = (event) => {
        this._ruleOptions.weekly.byweekday =
            event.detail.value !== ''
                ? event.detail.value.split(';').map((val) => Number(val))
                : '';
        this._updateValue();
    };

    _handleMonthDayChange = (event) => {
        if (event.target.classList.contains('selected')) {
            this._ruleOptions.monthly.bymonthday =
                this._ruleOptions.monthly.bymonthday.filter(
                    (val) => val !== event.target.dataset.value
                );
        } else {
            this._ruleOptions.monthly.bymonthday.push(
                event.target.dataset.value
            );
        }
        this._updateValue();
    };

    _handleMonthTabChange = (event) => {
        this._monthActiveSection = event.detail.activetab;
        if (this._monthActiveSection === PATTERNSECTIONINDEX) {
            if (!this._ruleOptions.bysetpos) {
                this._ruleOptions.bysetpos = 1;
            }
            if (this._ruleOptions.monthly.byweekday.length === 0) {
                this._ruleOptions.monthly.byweekday = [0];
            }
        }
        this._updateValue();
    };

    _handleMonthSetposChange = (event) => {
        this._ruleOptions.monthly.bysetpos = Number(event.detail.value);
        this._updateValue();
    };

    _handleWeekdaySelectChange = (event) => {
        if (!Number.isNaN(Number(event.detail.value))) {
            this._ruleOptions.monthly.byweekday = [Number(event.detail.value)];
        } else {
            // not a number => is day, weekday or weekendday
            if (event.detail.value === 'weekday') {
                this._ruleOptions.monthly.byweekday = [0, 1, 2, 3, 4];
            }
            if (event.detail.value === 'day') {
                this._ruleOptions.monthly.byweekday = [0, 1, 2, 3, 4, 5, 6];
            }
            if (event.detail.value === 'weekendday') {
                this._ruleOptions.monthly.byweekday = [5, 6];
            }
        }
        this._updateValue();
    };

    // start YEARLY fields event handlers
    _handleYearTabChange = (event) => {
        if (event.detail.activetab === YEARLYDAYSECTIONINDEX) {
            if (this._ruleOptions.yearly.bymonth.length > 1) {
                // it's possible that there are more than one values in the array if the user
                // switched from another tab to this one
                this._ruleOptions.yearly.bymonth =
                    this._ruleOptions.yearly.bymonth[0];
            }
            if (!this._ruleOptions.yearly.bymonthday) {
                this._ruleOptions.yearly.bymonthday = 1;
            }
        }
        this._yearActiveSection = event.detail.activetab;
        this._updateValue();
    };

    _handleYearlyMonthSelectChange = (event) => {
        this._ruleOptions.yearly.bymonth = [Number(event.detail.value)];
        this._updateValue();
    };

    _handleYearlyDayInputChange = (event) => {
        this._ruleOptions.yearly.bymonthday = Number(event.detail.value);
        this._updateValue();
    };

    _handleYearlyMonthCheckboxgroupChange = (event) => {
        this._ruleOptions.yearly.bymonth =
            event.detail.value !== ''
                ? event.detail.value.split(';').map((val) => Number(val))
                : '';
        this._updateValue();
    };

    _handleYearlySetPosChange = (event) => {
        this._ruleOptions.yearly.bysetpos = Number(event.detail.value);
        this._updateValue();
    };

    _handleYearlyWeekdaySelectChange = (event) => {
        if (!Number.isNaN(Number(event.detail.value))) {
            this._ruleOptions.yearly.byweekday = [Number(event.detail.value)];
        } else {
            // not a number => is day, weekday or weekendday
            if (event.detail.value === 'weekday') {
                this._ruleOptions.yearly.byweekday = [0, 1, 2, 3, 4];
            }
            if (event.detail.value === 'day') {
                this._ruleOptions.yearly.byweekday = [0, 1, 2, 3, 4, 5, 6];
            }
            if (event.detail.value === 'weekendday') {
                this._ruleOptions.yearly.byweekday = [5, 6];
            }
        }
        this._updateValue();
    };

    _handleEndDateChange = (event) => {
        this._ruleOptions.until =
            event.detail.value !== '' ? new Date(event.detail.value) : null;
        this._updateValue();
    };

    _handleCountChange = (event) => {
        this._ruleOptions.count = Number(event.detail.value);
        this._updateValue();
    };

    _handleEndTabChange = (event) => {
        this._endActiveSection = event.detail.activetab;
        this._updateValue();
    };
    // end YEARLY fields event handlers

    _getWeekdaySelectKey = (value) => {
        if (value) {
            if (value.length === 7) {
                return 'day';
            }
            if (value.length === 5) {
                if (value.includes(5) || value.includes(6)) {
                    return '0';
                }
                return 'weekday';
            }
            if (value.length === 2) {
                if (value.includes(5) && value.includes(6)) {
                    return 'weekendday';
                }
                return '0';
            }
            return `${value[0]}`;
        }
        return '0';
    };

    attributeChangedCallback(property, oldValue, newValue) {
        this._unbindEvents();
        if (property === 'value') {
            this._loadFromRRuleString(newValue);
        }
        this.render();
        this._bindEvents();
    }

    _renderWeekdays(mode = 'checkboxes') {
        if (mode === 'buttons') {
            return `<div class="weekdaywrapper">
                <sketch-text variant="footnote">${this.labelOnWeekdays}</sketch-text>
                        <div class="days">
                            <div class="daybutton">${this.labelMonday}</div>
                            <div class="daybutton">${this.labelTuesday}</div>
                            <div class="daybutton">${this.labelWednesday}</div>
                            <div class="daybutton selected">${this.labelThursday}</div>
                            <div class="daybutton">${this.labelFriday}</div>
                            <div class="daybutton">${this.labelSaturday}</div>
                            <div class="daybutton">${this.labelSunday}</div>
                        </div>
                    </div>`;
        }
        return `
        <div class="weekdaywrapper">
            <sketch-checkboxgroup
                id="weekdays"
                fieldId="weekdays"
                label="${this.labelOnWeekdays}"
                variant="${this.checkboxdirection}"
                ${this.disabled ? 'disabled="true"' : ''}
                value="${this._ruleOptions.weekly.byweekday ? this._ruleOptions.weekly.byweekday.join(';') : ''}"
                >
                    <sketch-checkbox value="0" label="${this.labelMonday}"></sketch-checkbox>
                    <sketch-checkbox value="1" label="${this.labelTuesday}"></sketch-checkbox>
                    <sketch-checkbox value="2" label="${this.labelWednesday}"></sketch-checkbox>
                    <sketch-checkbox value="3" label="${this.labelThursday}"></sketch-checkbox>
                    <sketch-checkbox value="4" label="${this.labelFriday}"></sketch-checkbox>
                    <sketch-checkbox value="5" label="${this.labelSaturday}"></sketch-checkbox>
                    <sketch-checkbox value="6" label="${this.labelSunday}"></sketch-checkbox>
            </sketch-checkboxgroup>
        </div>`;
    }

    _renderMonthButtons = (selectedDays) => {
        let renderString = ``;
        for (let i = 1; i <= 31; i += 1) {
            renderString = `${renderString}<div class="daybutton small ${selectedDays.includes(i.toString()) ? 'selected' : ''}" data-value="${i}">${i}</div>`;
        }
        return renderString;
    };

    _renderPositionSelect = (fieldId, selectedValue) => {
        return `
        <sketch-select 
            label="" 
            id="${fieldId}" 
            fieldId="${fieldId}" 
            nomargin="true"
            ${this.disabled ? 'disabled="true"' : ''}>
                <option value="1" ${selectedValue === 1 ? 'selected' : ''}>${this.labelFirst}</option>
                <option value="2" ${selectedValue === 2 ? 'selected' : ''}>${this.labelSecond}</option>
                <option value="3" ${selectedValue === 3 ? 'selected' : ''}>${this.labelThird}</option>
                <option value="4" ${selectedValue === 4 ? 'selected' : ''}>${this.labelFourth}</option>
                <option value="-1" ${selectedValue === -1 ? 'selected' : ''}>${this.labelLast}</option>
        </sketch-select>`;
    };

    _renderWeekdaySelect = (fieldId, selectedValue) =>
        `
        <sketch-select 
            label="" 
            id="${fieldId}" 
            fieldId="${fieldId}" 
            nomargin="true"
            ${this.disabled ? 'disabled="true"' : ''}>
                <option value="0" ${selectedValue === '0' ? 'selected' : ''}>${this.labelMonday}</option>
                <option value="1" ${selectedValue === '1' ? 'selected' : ''}>${this.labelTuesday}</option>
                <option value="2" ${selectedValue === '2' ? 'selected' : ''}>${this.labelWednesday}</option>
                <option value="3" ${selectedValue === '3' ? 'selected' : ''}>${this.labelThursday}</option>
                <option value="4" ${selectedValue === '4' ? 'selected' : ''}>${this.labelFriday}</option>
                <option value="5" ${selectedValue === '5' ? 'selected' : ''}>${this.labelSaturday}</option>
                <option value="6" ${selectedValue === '6' ? 'selected' : ''}>${this.labelSunday}</option>
                <option value="day" ${selectedValue === 'day' ? 'selected' : ''}>${this.labelDay}</option>
                <option value="weekday" ${selectedValue === 'weekday' ? 'selected' : ''}>${this.labelWeekday}</option>
                <option value="weekendday" ${selectedValue === 'weekendday' ? 'selected' : ''}>${this.labelWeekendday}</option>
        </sketch-select>`;

    _renderMonthFields = () =>
        `
        <sketch-tabsection
            id="monthtabs"
            tabTitles="${this.labelPattern};${this.labelMonthDays}"
            tabsPosition="top"
            tabStyle="buttons"
            nrOfSections="2"
            ${this.disabled ? 'freezeActiveTab' : ''}
            activeSection="${this._monthActiveSection}">
            <div slot="content-${PATTERNSECTIONINDEX}" class="patternwrapper">
                ${this._renderPositionSelect('monthlysetpos', this._ruleOptions.monthly.bysetpos)}
                ${this._renderWeekdaySelect('weekdayselect', this._getWeekdaySelectKey(this._ruleOptions.monthly.byweekday))}
            </div>
            <div class="days monthgrid" slot="content-${DAYSECTIONINDEX}">
                ${this._renderMonthButtons(this._ruleOptions.monthly.bymonthday || [])}
            </div>
        </sketch-tabsection>
            `;

    _loadFromRRuleString = (rruleString) => {
        const rule = RRule.fromString(rruleString);
        this._ruleOptions.freq = rule.origOptions.freq;
        this._ruleOptions.interval = rule.origOptions.interval;
        this._ruleOptions.dtstart = rule.origOptions.dtstart || new Date();
        if (rule.origOptions.until) {
            this._endActiveSection = ENDUNTILSECTIONINDEX;
            this._ruleOptions.until = rule.origOptions.until;
        }
        if (rule.origOptions.count) {
            this._endActiveSection = ENDCOUNTSECTIONINDEX;
            this._ruleOptions.count = rule.origOptions.count;
        }

        if (rule.origOptions.freq === RRule.WEEKLY) {
            if (rule.origOptions.byweekday) {
                this._ruleOptions.weekly.byweekday =
                    rule.origOptions.byweekday.map(
                        (weekday) => weekday.weekday
                    );
            }
        }
        if (rule.origOptions.freq === RRule.MONTHLY) {
            if (rule.origOptions.bysetpos) {
                this._monthActiveSection = PATTERNSECTIONINDEX;
                this._ruleOptions.monthly.bysetpos = rule.origOptions.bysetpos;
                this._ruleOptions.monthly.byweekday =
                    rule.origOptions.byweekday.map(
                        (weekday) => weekday.weekday
                    );
            } else {
                this._monthActiveSection = DAYSECTIONINDEX;
                if (rule.origOptions.bymonthday) {
                    if (Array.isArray(rule.origOptions.bymonthday)) {
                        this._ruleOptions.monthly.bymonthday =
                            rule.origOptions.bymonthday.map((val) => `${val}`);
                    } else {
                        if (rule.origOptions.bymonthday) {
                            this._ruleOptions.monthly.bymonthday = [
                                `${rule.origOptions.bymonthday}`,
                            ];
                        } else {
                            this._ruleOptions.monthly.bymonthday = [
                                `${this._ruleOptions.dtstart.getDate()}`,
                            ];
                        }
                    }
                } else {
                    this._ruleOptions.bymonthday = [];
                }
            }
        }
        if (rule.origOptions.freq === RRule.YEARLY) {
            if (rule.origOptions.bysetpos) {
                this._yearActiveSection = YEARLYPATTERNSECTIONINDEX;
                this._ruleOptions.yearly.bysetpos =
                    rule.origOptions.bysetpos || 1;
                this._ruleOptions.yearly.byweekday =
                    rule.origOptions.byweekday.map(
                        (weekday) => weekday.weekday
                    ) || [0];
            } else {
                if (rule.origOptions.bymonthday) {
                    this._yearActiveSection = YEARLYDAYSECTIONINDEX;
                    this._ruleOptions.yearly.bymonth =
                        typeof rule.origOptions.bymonth === 'number'
                            ? [rule.origOptions.bymonth]
                            : [];
                    this._ruleOptions.yearly.bymonthday =
                        rule.origOptions.bymonthday || 1;
                } else {
                    this._yearActiveSection = YEARLYMONTHSECTIONINDEX;
                    if (rule.origOptions.bymonth) {
                        if (Array.isArray(rule.origOptions.bymonth)) {
                            this._ruleOptions.yearly.bymonth =
                                rule.origOptions.bymonth;
                        } else {
                            this._ruleOptions.yearly.bymonth = [
                                rule.origOptions.bymonth,
                            ];
                        }
                    } else {
                        this._ruleOptions.yearly.bymonth = [];
                    }
                }
            }
        }
    };

    _calculateRRule = () => {
        const definitiveOptions = {
            interval: this._ruleOptions.interval,
            freq: this._ruleOptions.freq,
            dtstart: this._ruleOptions.dtstart,
        };
        if (this._endActiveSection === ENDUNTILSECTIONINDEX) {
            if (this._ruleOptions.until) {
                definitiveOptions.until = this._ruleOptions.until;
            }
        }
        if (this._endActiveSection === ENDCOUNTSECTIONINDEX) {
            if (this._ruleOptions.count) {
                definitiveOptions.count = this._ruleOptions.count;
            }
        }
        if (this._ruleOptions.freq === RRule.WEEKLY) {
            if (
                this._ruleOptions.weekly.byweekday &&
                this._ruleOptions.weekly.byweekday.length > 0
            ) {
                definitiveOptions.byweekday =
                    this._ruleOptions.weekly.byweekday;
            } else {
                definitiveOptions.byweekday = [
                    definitiveOptions.dtstart.getDay() - 1,
                ];
            }
        }
        if (this._ruleOptions.freq === RRule.MONTHLY) {
            if (this._monthActiveSection === PATTERNSECTIONINDEX) {
                definitiveOptions.bysetpos = this._ruleOptions.monthly.bysetpos;
                definitiveOptions.byweekday =
                    this._ruleOptions.monthly.byweekday;
            }
            if (this._monthActiveSection === DAYSECTIONINDEX) {
                if (
                    this._ruleOptions.monthly.bymonthday &&
                    this._ruleOptions.monthly.bymonthday.length > 0
                ) {
                    definitiveOptions.bymonthday =
                        this._ruleOptions.monthly.bymonthday;
                } else {
                    this._ruleOptions.monthly.bymonthday = [
                        `${this._ruleOptions.dtstart.getDate()}`,
                    ];
                }
            }
        }
        if (this._ruleOptions.freq === RRule.YEARLY) {
            if (this._yearActiveSection === YEARLYPATTERNSECTIONINDEX) {
                definitiveOptions.bysetpos = this._ruleOptions.yearly.bysetpos;
                definitiveOptions.byweekday =
                    this._ruleOptions.yearly.byweekday;
                definitiveOptions.bymonth = this._ruleOptions.yearly.bymonth;
            }
            if (this._yearActiveSection === YEARLYDAYSECTIONINDEX) {
                definitiveOptions.bymonth = this._ruleOptions.yearly.bymonth;
                definitiveOptions.bymonthday =
                    this._ruleOptions.yearly.bymonthday;
            }
            if (this._yearActiveSection === YEARLYMONTHSECTIONINDEX) {
                if (
                    this._ruleOptions.yearly.bymonth &&
                    this._ruleOptions.yearly.bymonth.length > 0
                ) {
                    definitiveOptions.bymonth =
                        this._ruleOptions.yearly.bymonth;
                } else {
                    definitiveOptions.bymonth = [
                        this._ruleOptions.dtstart.getMonth() + 1,
                    ];
                }
            }
        }
        const rule = new RRule(definitiveOptions);
        return rule;
    };

    _renderYearFields = () =>
        `<sketch-tabsection
            id="yeartabs"
            tabTitles="${this.labelOnce};${this.labelInMonths};${this.labelPattern}"
            tabsPosition="top"
            tabStyle="buttons"
            nrOfSections="3"
            activeSection="${this._yearActiveSection}"
            ${this.disabled ? 'freezeActiveTab' : ''}
        >
            <div slot="content-${YEARLYDAYSECTIONINDEX}" class="flexrow">
                <sketch-input
                    type="number" 
                    label="" 
                    maxwidth="100px" 
                    id="yearlydayinput"
                    fieldId="yearlydayinput"
                    value="${this._ruleOptions.yearly.bymonthday}"
                    nomargin="true"
                    ${this.disabled ? 'disabled="true"' : ''}>
                </sketch-input>
                <div class="suffix">
                    ${this._renderMonthSelection('yearlymonthselect', this._isMonthSelected)}
                </div>
            </div>
            <div slot="content-${YEARLYMONTHSECTIONINDEX}">
                ${this._renderMonthCheckboxGroup()}
            </div>
            <div slot="content-${YEARLYPATTERNSECTIONINDEX}" class="flexrow">
                ${this._renderPositionSelect('yearsetpos', this._ruleOptions.yearly.bysetpos)}
                <div class="suffix">
                    ${this._renderWeekdaySelect('yearlyweekdayselect', this._getWeekdaySelectKey(this._ruleOptions.yearly.byweekday))}
                </div>
                <div class="suffix flexrow center">
                    <sketch-text variant="label">${this.labelOfMonth}</sketch-text>
                    <div class="suffix">
                        ${this._renderMonthSelection('yearlymonthselect', this._isMonthSelected)}
                    </div>
                </div>
            </div>
        </sketch-tabsection>`;

    _isMonthSelected = (month) => {
        return this._ruleOptions.yearly.bymonth
            ? this._ruleOptions.yearly.bymonth[0] === month
            : false;
    };

    _renderMonthSelection = (fieldId, isSelectedFunction) => {
        return `
        <sketch-select 
            label="" 
            id="${fieldId}" 
            fieldId="${fieldId}" 
            nomargin="true"
            ${this.disabled ? 'disabled="true"' : ''}>
                <option value="1" ${isSelectedFunction(1) ? 'selected' : ''}>${this.labelJanuary}</option>
                <option value="2" ${isSelectedFunction(2) ? 'selected' : ''}>${this.labelFebruary}</option>
                <option value="3" ${isSelectedFunction(3) ? 'selected' : ''}>${this.labelMarch}</option>
                <option value="4" ${isSelectedFunction(4) ? 'selected' : ''}>${this.labelApril}</option>
                <option value="5" ${isSelectedFunction(5) ? 'selected' : ''}>${this.labelMay}</option>
                <option value="6" ${isSelectedFunction(6) ? 'selected' : ''}>${this.labelJune}</option>
                <option value="7" ${isSelectedFunction(7) ? 'selected' : ''}>${this.labelJuly}</option>
                <option value="8" ${isSelectedFunction(8) ? 'selected' : ''}>${this.labelAugust}</option>
                <option value="9" ${isSelectedFunction(9) ? 'selected' : ''}>${this.labelSeptember}</option>
                <option value="10" ${isSelectedFunction(10) ? 'selected' : ''}>${this.labelOctober}</option>
                <option value="11" ${isSelectedFunction(11) ? 'selected' : ''}>${this.labelNovember}</option>
                <option value="12" ${isSelectedFunction(12) ? 'selected' : ''}>${this.labelDecember}</option>
        </sketch-select>`;
    };

    _renderMonthCheckboxGroup = () => {
        return `
            <sketch-checkboxgroup
                id="yearlymonthcheckboxgroup"
                fieldId="yearlymonthcheckboxgroup"
                label=""
                variant="${this.checkboxdirection}"
                value="${this._ruleOptions.yearly.bymonth.join(';')}"
                ${this.disabled ? 'disabled="true"' : ''}
            >
                <sketch-checkbox value="1" label="${this.labelJanuary}"></sketch-checkbox>
                <sketch-checkbox value="2" label="${this.labelFebruary}"></sketch-checkbox>
                <sketch-checkbox value="3" label="${this.labelMarch}"></sketch-checkbox>
                <sketch-checkbox value="4" label="${this.labelApril}"></sketch-checkbox>
                <sketch-checkbox value="5" label="${this.labelMay}"></sketch-checkbox>
                <sketch-checkbox value="6" label="${this.labelJune}"></sketch-checkbox>
                <sketch-checkbox value="7" label="${this.labelJuly}"></sketch-checkbox>
                <sketch-checkbox value="8" label="${this.labelAugust}"></sketch-checkbox>
                <sketch-checkbox value="9" label="${this.labelSeptember}"></sketch-checkbox>
                <sketch-checkbox value="10" label="${this.labelOctober}"></sketch-checkbox>
                <sketch-checkbox value="11" label="${this.labelNovember}"></sketch-checkbox>
                <sketch-checkbox value="12" label="${this.labelDecember}"></sketch-checkbox>
            </sketch-checkboxgroup>
        `;
    };

    render() {
        this.$shadowRoot.innerHTML = `
        <style>
        :host {
            display: flex;
            flex-direction: column;
            grid-column: span 2;
            ${
                this.nomargin === 'true'
                    ? ''
                    : 'margin-bottom: var(--sketchSpacing6);'
            }
        }
        .appointmenttimewrapper {
            display: flex;
        }
        .subtitle {
            margin-bottom: var(--sketchSpacing4);
        }
        .flexrow {
            display: flex;
            align-items: flex-end;
        }
        .flexrow.center {
            align-items: center;
        }
        .suffix {
            margin-left: var(--sketchSpacing2);
        }
        .days {
            display: flex;
        }
        .monthgrid {
            display: grid;
            grid-template-columns: repeat(6,auto) 1fr;
            justify-content: left; /* align to left */
            gap: var(--sketchSpacing1);
        }
        .daybutton {
            margin-right: var(--sketchSpacing2);
            border-radius: var(--sketchBorderRadiusButton);
            padding: var(--sketchSpacing2) var(--sketchSpacing3);
            border: 0;
            min-width: 20px;
            min-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--sketchColorNeutralLight);
        }
        .daybutton.small {
            width: 20px;
        }
        .daybutton:hover {
            cursor: pointer;
            background-color: var(--sketchColorPrimaryExtraLight);
        }
        .daybutton.selected {
            background-color: var(--sketchBackgroundColorPrimary);
            color: var(--sketchColorPrimaryButton);
        }
        .patternwrapper {
            display: flex;
        }
        .patternwrapper * {
            margin-right: var(--sketchSpacing2);
        }
        #yearlydayinput,
        #count {
            flex-basis: 100px;
            }
        #interval {
            width: 100px;
        }
        .endwrapper {
            margin-top: var(--sketchSpacing4);
        }
        .weekdaywrapper {
            margin-top: var(--sketchSpacing6);
        }
        .separator {
            border: 0;
            height: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            margin-top: var(--sketchSpacing8);
            margin-bottom: var(--sketchSpacing6);
        }
        @media only screen and (min-width: 576px) {
            :host {
                ${
                    this.nomargin === 'true'
                        ? ''
                        : 'margin-bottom: var(--sketchSpacing6);'
                }
                ${
                    this.subgrid
                        ? `display: grid;
                grid-template-columns: subgrid;`
                        : ''
                }
            }
        }
        </style>
        ${
            this.label
                ? `<label for="${this.fieldId}">
            <sketch-text variant="footnote" color=${
                this.hasError ? 'error' : ''
            }>
                ${this.label}
            </sketch-text>
        </label>`
                : ''
        }
        <div class="wrapper" id="${this.fieldId}">
            <div class="startdateanchor" tabindex="0">
                <sketch-date
                    label="${this.labelStartDate}" 
                    id="startdate" 
                    fieldId="startdate" 
                    name="${this.name}"
                    value="${this._ruleOptions.dtstart.toISOString()}"
                    mode="js"
                    ${this.minDate ? `minDate="${this.minDate}"` : ''}
                    ${this.maxDate ? `maxDate="${this.maxDate}"` : ''}
                    ${this.disabled ? 'disabled="true"' : ''}>
                </sketch-date>
            </div>
            <slot name="slot-afterstart"></slot>
            <div class="flexrow">
                <div class="intervalanchor" tabindex="0">
                    <sketch-input 
                        fieldId="interval"
                        id="interval"
                        label="${this.labelInterval}"
                        type="number" 
                        maxwidth="100px" 
                        nomargin="true"
                        value="${this._ruleOptions.interval}"
                        ${this.disabled ? 'disabled="true"' : ''}>
                    </sketch-input>
                </div>
                <div class="suffix">
                    <sketch-select 
                        id="frequency" 
                        fieldId="frequency" 
                        label="" 
                        nomargin="true"
                        ${this.disabled ? 'disabled="true"' : ''}>
                            <option value="${RRule.DAILY}" ${this._ruleOptions.freq === RRule.DAILY ? 'selected' : ''}>${this.labelFrequencyDays}</option>
                            <option value="${RRule.WEEKLY}" ${this._ruleOptions.freq === RRule.WEEKLY ? 'selected' : ''}>${this.labelFrequencyWeeks}</option>
                            <option value="${RRule.MONTHLY}" ${this._ruleOptions.freq === RRule.MONTHLY ? 'selected' : ''}>${this.labelFrequencyMonths}</option>
                            <option value="${RRule.YEARLY}" ${this._ruleOptions.freq === RRule.YEARLY ? 'selected' : ''}>${this.labelFrequencyYears}</option>
                    </sketch-select>
                </div>
            </div>
            
            ${this._ruleOptions.freq === RRule.WEEKLY ? this._renderWeekdays() : ``}
            ${this._ruleOptions.freq === RRule.MONTHLY ? this._renderMonthFields() : ``}
            ${this._ruleOptions.freq === RRule.YEARLY ? this._renderYearFields() : ``}
            <div class="endwrapper" tabindex="0">
                <sketch-tabsection
                    id="stoptabs"
                    tabTitles="${this.labelEndDateButton};${this.labelEndCountButton}"
                    tabsPosition="top"
                    tabStyle="buttons"
                    activeSection="${this._endActiveSection}"
                    nrOfSections="2"
                    ${this.disabled ? 'freezeActiveTab' : ''}
                    >
                        <sketch-date 
                            slot="content-1"
                            label="" 
                            id="enddate" 
                            fieldId="enddate" 
                            value="${this._ruleOptions.until.toISOString()}"
                            minDate="${incrementDate(this._ruleOptions.dtstart, 1).toISOString()}"
                            ${this.maxDate ? `maxDate="${this.maxDate}"` : ''}
                            mode="js"
                            ${this.disabled ? 'disabled="true"' : ''}>
                        </sketch-date>
                        <div class="flexrow center" slot="content-2">
                            <sketch-input 
                                id="count"
                                fieldId="count"
                                label=""
                                type="number" 
                                maxwidth="100px" 
                                nomargin="true"
                                value="${this._ruleOptions.count}"
                                ${this.disabled ? 'disabled="true"' : ''}>
                            </sketch-input>
                            <div class="suffix">
                                <sketch-text variant="label">${this.labelRepeatCountSuffix}</sketch-text>
                            </div>
                        </div>
                </sketch-tabsection>
            </div>
        </div>`;
    }
}
