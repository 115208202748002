import styles from './input.style.css';
import {isBooleanAttributeTrue} from '../utils/index.js';

export class SketchNumber extends HTMLElement {
    /* for form elements */
    static formAssociated = true;

    $input;

    $internals;

    $fallbackmode = false;

    $shadowRoot;

    static get observedAttributes() {
        return [
            'disabled',
            'fieldId',
            'hasError',
            'hasinfo',
            'label',
            'max',
            'maxwidth',
            'min',
            'name',
            'numbertype',
            'placeholder',
            'readonly',
            'required',
            'step',
            'subgrid',
            'value',
        ];
    }

    constructor() {
        super();
        /* for form elements */
        this.$internals = this.attachInternals();
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: true,
        });
    }

    get variant() {
        return this.getAttribute('variant');
    }

    set variant(value) {
        this.setAttribute('variant', value);
    }

    get step() {
        return (
            this.getAttribute('step') || (this.numbertype = 'integer' ? 1 : 0.1)
        );
    }

    set variant(value) {
        this.setAttribute('variant', value);
    }

    get required() {
        return isBooleanAttributeTrue(this.getAttribute('required'));
    }

    set required(value) {
        this.setAttribute('required', value);
    }

    get placeholder() {
        return this.getAttribute('placeholder');
    }

    set placeholder(value) {
        this.setAttribute('placeholder', value);
    }

    get numbertype() {
        return this.getAttribute('numbertype') || 'integer';
    }

    set numbertype(value) {
        this.setAttribute('numbertype', value);
    }

    get name() {
        return this.getAttribute('name');
    }

    set name(value) {
        this.setAttribute('name', value);
    }

    get label() {
        return this.getAttribute('label');
    }

    set label(value) {
        this.setAttribute('label', value);
    }

    get fieldId() {
        return this.getAttribute('fieldId');
    }

    set fieldId(value) {
        this.setAttribute('fieldId', value);
    }

    get hasError() {
        return isBooleanAttributeTrue(this.getAttribute('hasError'));
    }

    set hasError(value) {
        this.setAttribute('hasError', value);
    }

    get maxwidth() {
        return this.getAttribute('maxwidth');
    }

    set maxwidth(value) {
        this.setAttribute('maxwidth', value);
    }

    get disabled() {
        return isBooleanAttributeTrue(this.getAttribute('disabled'));
    }

    set disabled(value) {
        this.setAttribute('disabled', value);
    }

    get value() {
        return this.getAttribute('value').replaceAll('"', '&quot;');
    }

    set value(value) {
        this.setAttribute('value', value.replaceAll('"', '&quot;'));
    }

    get readonly() {
        return isBooleanAttributeTrue(this.getAttribute('readonly'));
    }

    set readonly(value) {
        this.setAttribute('readonly', value);
    }

    get max() {
        return this.getAttribute('max');
    }

    set max(value) {
        this.setAttribute('max', value);
    }

    get min() {
        return this.getAttribute('min');
    }

    set min(value) {
        this.setAttribute('min', value);
    }

    get subgrid() {
        return isBooleanAttributeTrue(this.getAttribute('subgrid'));
    }

    set subgrid(value) {
        this.setAttribute('subgrid', value);
    }

    get fallbackmode() {
        return isBooleanAttributeTrue(this.getAttribute('fallbackmode'));
    }

    set fallbackmode(value) {
        this.setAttribute('fallbackmode', value);
    }

    get hasinfo() {
        return isBooleanAttributeTrue(this.getAttribute('hasinfo'));
    }

    set hasinfo(value) {
        this.setAttribute('hasinfo', value);
    }

    connectedCallback() {
        if (this.$fallbackmode) {
            this.fallbackmode = true;
        }
        this.render();
        this.$input = this.$shadowRoot.querySelector('input');
        this._bindInfoClick();
        this.$input.addEventListener('keyup', this._handleInput);
        this.$input.addEventListener('focusout', this._handleFocusOut);
        this.$input.addEventListener('change', this._handleChange);
        if (!this.fallbackmode) {
            this.$internals.setFormValue(this.value);
            this.$internals.setValidity(
                this.$input.validity,
                this.$input.validationMessage,
                this.$input
            );
        }
    }

    _bindInfoClick = () => {
        const infoIcon = this.$shadowRoot.querySelector('label > sketch-icon');
        if (infoIcon) {
            infoIcon.addEventListener('click', this._handleInfoClick);
        }
    };

    _handleInfoClick = () => {
        this.dispatchEvent(
            new MouseEvent('inforequested', {
                bubbles: true,
            })
        );
    };

    _handleInput = () => {
        this._updateValue(this.$input.value);
        this._touched = true;
    };

    _updateValue = (value) => {
        this.value = value;

        if (!this.fallbackmode) {
            this.$internals.setFormValue(value);
            this.$internals.setValidity(
                this.$input.validity,
                this.$input.validationMessage,
                this.$input
            );
        }
    };

    _handleFocusOut = () => {
        if (this._touched) {
            this.render();
            this.$input = this.$shadowRoot.querySelector('input');
            if (!this.fallbackmode) {
                this.$internals.setValidity(
                    this.$input.validity,
                    this.$input.validationMessage,
                    this.$input
                );
            }

            this.$input.addEventListener('keyup', this._handleInput);
            this.$input.addEventListener('focusout', this._handleFocusOut);
            this.$input.addEventListener('change', this._handleChange);
        }
    };

    _handleChange = (event) => {
        this._updateValue(event.target.value);
        this.dispatchEvent(
            new CustomEvent('change', {
                bubbles: true,
                detail: {
                    value: event.target.value,
                },
            })
        );
    };

    attributeChangedCallback(property, oldValue, newValue) {
        if (oldValue !== newValue) {
            if (property === 'disabled') {
                if (isBooleanAttributeTrue(newValue)) {
                    this.$input?.setAttribute(
                        'disabled',
                        isBooleanAttributeTrue(newValue)
                    );
                } else {
                    this.$input?.removeAttribute('disabled');
                }
            }
        }
    }

    render() {
        this.$shadowRoot.innerHTML = `
        <style>
        ${styles}
            :host {
                display: flex;
                flex-direction: column;
            }
            label {
                ${this.subgrid ? '' : `margin-bottom: var(--sketchSpacing1);`}
                ${this.hasinfo ? 'display: flex;align-items: center;' : ''}
    
                sketch-icon:hover {
                    cursor: pointer;
                }
            }
            .inputwrapper {
                display: flex;
                width: 100%;
                align-items: center;
            }
            slot[name="suffix"]::slotted(*) {
                margin: 0 0 0 var(--sketchSpacing3);
            }
            input {
                ${this.maxwidth ? `max-width:${this.maxwidth}` : 'max-width: 100%;'} 
            }
            @media only screen and (min-width: 576px) {
                :host {
                    ${
                        this.subgrid
                            ? `
                        display: grid;
                        grid-template-columns: subgrid;
                        grid-column: span 2;`
                            : ''
                    }
                    
                }
                label {
                    display: flex;
                    align-items: center;
                }
                
            }
            </style>
            <label for="${this.fieldId}"
                ><sketch-text variant="Label" color=${
                    this.hasError ? 'error' : ''
                }
                    >${this.label}</sketch-text
                >
                ${this.hasinfo ? '<sketch-icon icon="info" size="xs"></sketch-icon>' : ''}
                </label
            >
            <div class="inputwrapper">
                <input
                    id="${this.fieldId}"
                    name="${this.name}"
                    type="number"
                    step="${this.step}"
                    value="${this.value ? this.value : ''}"
                    ${this.disabled ? 'disabled' : ''}
                    ${this.max ? `max="${this.max}"` : ''}
                    ${this.min ? `min="${this.min}"` : ''}
                    ${this.required ? 'required' : ''}
                    ${this.readonly ? 'readonly="readonly"' : ''}
                    placeholder="${this.placeholder || ''}"
                />
                <slot name="suffix"></slot>
            </div>`;
    }
}
