import Quill from 'quill';
import {css} from '../utils/quill.js';

const quillOptions = {
    modules: {
        toolbar: [
            [{list: 'ordered'}, {list: 'bullet'}],
            ['bold', 'italic'], // toggled buttons
            [{header: [1, 2, 3, 4, 5, 6, false]}],
        ],
    },
    placeholder: 'Compose an epic...',
    theme: 'snow',
};

export class SketchHtmlWysiwig extends HTMLElement {
    /* for form elements */
    static formAssociated = true;

    _editor = null;

    $shadowRoot;

    static get observedAttributes() {
        return ['value', 'name', 'id', 'label'];
    }

    constructor() {
        super();
        /* for form elements */
        try {
            this.$internals = this.attachInternals();
        } catch (error) {
            console.log('### attachInternals error', error);
        }
    }

    get value() {
        return this.getAttribute('value');
    }

    set value(value) {
        this.setAttribute('value', value);
    }

    get name() {
        return this.getAttribute('name');
    }

    set name(value) {
        this.setAttribute('name', value);
    }

    get id() {
        return this.getAttribute('id');
    }

    set id(value) {
        this.setAttribute('id', value);
    }

    get label() {
        return this.getAttribute('label');
    }

    set label(value) {
        this.setAttribute('label', value);
    }

    connectedCallback() {
        this.render();
        this._hookup();
    }

    _hookup = () => {
        this._editor = new Quill(`#wysiwig_${this.id}`, quillOptions);
        this._editor.on('text-change', (delta, oldDelta, source) => {
            if (source === 'user') {
                this.dispatchEvent(
                    new CustomEvent('sk-change', {
                        bubbles: true,
                        detail: {
                            value: this._editor.getSemanticHTML(),
                        },
                    })
                );
                this.dispatchEvent(
                    new CustomEvent('change', {
                        bubbles: true,
                        detail: {
                            value: this._editor.getSemanticHTML(),
                        },
                    })
                );
            }
        });
    };

    attributeChangedCallback(property, oldValue, newValue) {
        if (property === 'value') {
            if (oldValue !== newValue) {
                this.render();
                this._hookup();
            }
        }
    }

    render() {
        this.innerHTML = `
        <style>
        #wysiwig_${this.id} h1,
        #wysiwig_${this.id} h2,
        #wysiwig_${this.id} h3,
        #wysiwig_${this.id} h4,
        #wysiwig_${this.id} h5,
        #wysiwig_${this.id} h6 {
            color: var(--sketchColorHeading);
        }
        #wysiwig_${this.id} h1 {
            font-size: var(--sketchFontSizeHeading1);
        }
        #wysiwig_${this.id} h2 {
            font-size: var(--sketchFontSizeHeading2);
        }
        #wysiwig_${this.id} h3 {
            font-size: var(--sketchFontSizeHeading3);
        }
        #wysiwig_${this.id} h4 {
            font-size: var(--sketchFontSizeHeading4);
        }
        #wysiwig_${this.id} h5 {
            font-size: var(--sketchFontSizeHeading5);
        }
        #wysiwig_${this.id} h6 {
            font-size: var(--sketchFontSizeHeading6);
        }
        ${css}
        </style>
        <div id="wysiwig_${this.id}">${this.value}</div>`;
    }
}
