import {editorCss} from '../utils/ace.js';
import {isBooleanAttributeTrue} from '../utils/index.js';

export class SketchHighlightEditor extends HTMLElement {
    /* for form elements */
    static formAssociated = true;
    static get observedAttributes() {
        return ['value', 'language', 'name', 'id', 'disabled', 'label'];
    }

    $editor;

    $editorbound;

    $internals;

    $fallbackmode = false;

    $shadowRoot;

    constructor() {
        super();
        /* for form elements */
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: true,
        });
        try {
            this.$internals = this.attachInternals();
        } catch (error) {
            this.$fallbackmode = true;
        }
        // add script element to load ace.js
        if (!document.querySelector('#acescript')) {
            const script = document.createElement('script');
            script.src = 'https://dev.anet.be/brocade/js/aceeditor/ace.js';
            script.type = 'text/javascript';
            script.id = 'acescript';
            script.onload = this.aceLoaded;
            document.head.appendChild(script);
        }
        document.addEventListener('ace-loaded', (event) => {
            this.initialize();
        });
    }

    get value() {
        return this.getAttribute('value') === ''
            ? ' '
            : this.getAttribute('value');
    }

    set value(val) {
        this.setAttribute('value', val);
    }

    get language() {
        return this.getAttribute('language') || 'html';
    }

    set language(value) {
        this.setAttribute('language', value);
    }

    get name() {
        return this.getAttribute('name');
    }

    set name(value) {
        this.setAttribute('name', value);
    }

    get id() {
        return this.getAttribute('id');
    }

    set id(value) {
        this.setAttribute('id', value);
    }

    get disabled() {
        return isBooleanAttributeTrue(this.getAttribute('disabled'));
    }

    set disabled(value) {
        this.setAttribute('disabled', value);
    }

    get label() {
        return this.getAttribute('label') || undefined;
    }

    set label(value) {
        this.setAttribute('label', value);
    }

    get fallbackmode() {
        return isBooleanAttributeTrue(this.getAttribute('fallbackmode'));
    }

    set fallbackmode(value) {
        this.setAttribute('fallbackmode', value);
    }

    connectedCallback() {
        if (this.$fallbackmode) {
            this.fallbackmode = true;
        }
        this.render();
        if (!this.$fallbackmode) {
            this.$internals.setFormValue(this.value);
        }
    }

    _isFormatSupported = () => {
        const supportedLangauges = ['html', 'css', 'javascript', 'json', 'xml'];
        return supportedLangauges.includes(this.language);
    };

    _getFormatLanguage = () => {
        if (this.language === 'html') {
            return 'html';
        }
        if (this.language === 'css') {
            return 'css';
        }
        if (this.language === 'javascript') {
            return 'js';
        }
        if (this.language === 'json') {
            return 'json';
        }
        return 'html';
    };

    attributeChangedCallback(property, oldValue, newValue) {
        if (property === 'value') {
            // value is changed from outside of component => update
            if (oldValue !== newValue) {
                this.$editor.setValue(newValue);
            }
        } else {
            this.initialize();
        }
    }

    aceLoaded = () => {
        const event = new Event('ace-loaded', {
            bubbles: true,
            composed: true,
        });
        document.dispatchEvent(event);
    };

    initialize = () => {
        if (this.value) {
            const editElement = this.$shadowRoot.querySelector('.editor');
            try {
                this.$editor = ace.edit(editElement);
                this.$editor.setTheme('ace/theme/chrome');
                this.$editor.session.setMode(`ace/mode/${this.language}`);
                this.$editor.setShowPrintMargin(false);
                this.$editor.setOption('maxLines', 100);
                this.$editor.setOption('showLineNumbers', false);
                this.$editor.setOption('showGutter', false);
                this.$editor.setOption('wrap', 'free'); // wrap based on view width
                this.$editor.session.setTabSize(4);
                if (this.disabled) {
                    this.$editor.setReadOnly(true);
                }
                if (!this.$editorbound) {
                    this.$editor.session.on('change', this._handleChange);
                    this.$editorbound = true;
                }
            } catch (error) {}
        }
    };

    _handleChange = () => {
        const editorValue = this.$editor.session.getValue();
        if (!this.fallbackmode) {
            this.$internals.setFormValue(editorValue);
        }
        this.dispatchEvent(
            new CustomEvent('change', {
                bubbles: true,
                detail: {
                    value: editorValue,
                },
            })
        );
    };

    _escapeHtml = (htmlString) => {
        return htmlString
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;');
    };

    render() {
        if (this.value) {
            this.$shadowRoot.innerHTML = `
                <style>
                .editor {
                    min-height: 200px;
                    width: 100%;
                    border: var(--sketchBorderInput);
                    border-radius: var(--sketchBorderRadiusInput);
                }
                label {
                    display: block;
                    margin-bottom: var(--sketchSpacing4);
                }
                .wrapper {
                    display: flex;
                    justify-content: ${
                        this.label ? 'space-between' : 'flex-end'
                    };
                    margin-bottom: var(--sketchSpacing2);
                }
    
                ${editorCss}
                </style>
                <div class="wrapper">
                ${
                    this.label
                        ? `<label for="${this.id}">
                    <sketch-text variant="Label">
                        ${this.label}
                    </sketch-text>
                </label>`
                        : ''
                }
                </div>
                <div id="${this.id}" class="editor">${this._escapeHtml(
                    this.value
                )}</div>
                
                `;
            this.initialize();
        } else {
            this.$shadowRoot.innerHTML = ``;
        }
    }
}
