import {isBooleanAttributeTrue} from '../utils/index.js';

export class SketchTabsection extends HTMLElement {
    static formAssociated = true;

    $shadowRoot;

    $internals;

    $fallbackmode = false;

    static get observedAttributes() {
        return [
            'nrOfSections',
            'activeSection',
            'tabTitles',
            'tabsPosition',
            'fancy',
            'tabStyle',
            'freezeActiveTab',
            'name',
        ];
    }

    constructor() {
        super();
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: true,
        });
        try {
            this.$internals = this.attachInternals();
        } catch (error) {
            this.$fallbackmode = true;
        }
    }

    get nrOfSections() {
        return this.getAttribute('nrOfSections');
    }

    set nrOfSections(value) {
        this.setAttribute('nrOfSections', value);
    }

    get activeSection() {
        return this.getAttribute('activeSection');
    }

    set activeSection(value) {
        this.setAttribute('activeSection', value);
    }

    get tabTitles() {
        return this.getAttribute('tabTitles');
    }

    set tabTitles(value) {
        this.setAttribute('tabTitles', value);
    }

    get fancy() {
        return this.getAttribute('fancy');
    }

    set fancy(value) {
        this.setAttribute('fancy', value);
    }

    get freezeActiveTab() {
        return isBooleanAttributeTrue(this.getAttribute('freezeActiveTab'));
    }

    set freezeActiveTab(value) {
        this.setAttribute('freezeActiveTab', value);
    }

    get tabsPosition() {
        return this.getAttribute('tabsPosition') || 'left';
    }

    set tabsPosition(value) {
        this.setAttribute('tabsPosition', value);
    }

    get tabStyle() {
        if (this.tabsPosition === 'left') {
            return 'links';
        }
        return this.getAttribute('tabStyle') || 'tabs';
    }

    set tabStyle(value) {
        this.setAttribute('tabStyle', value);
    }

    get name() {
        return this.getAttribute('name');
    }

    set name(value) {
        this.setAttribute('name', value);
    }

    get fallbackmode() {
        return isBooleanAttributeTrue(this.getAttribute('fallbackmode'));
    }

    set fallbackmode(value) {
        this.setAttribute('fallbackmode', value);
    }

    connectedCallback() {
        if (this.$fallbackmode) {
            this.fallbackmode = true;
        }
        this.render();
        if (!this.fallbackmode) {
            this.$internals.setFormValue(this.activeSection);
        }
        this._bind();
    }

    _renderTabs = () =>
        this.tabTitles
            .split(';')
            .map((title, index) => this._renderSingleTab(title, index))
            .reduce((prev, curr) => `${prev}${curr}`, '');

    _renderSingleTab = (tabTitle, tabIndex) => {
        const isActive = Number(this.activeSection) === tabIndex + 1;
        if (this.tabStyle === 'buttons') {
            return `<sketch-button 
                label="${tabTitle}" 
                variant="${isActive ? 'primary' : 'default'}" 
                data-tabindex="${tabIndex + 1}"
                ${tabIndex + 1 !== Number(this.nrOfSections) ? 'noEndRounding' : ''}
                ${tabIndex > 0 ? 'noStartRounding' : ''}
                >
            </sketch-button>`;
        } else {
            return `<div class="${this._getTabClass()} ${
                isActive ? 'active' : ''
            }">${this._renderTabText(isActive, tabIndex, tabTitle)}</div>`;
        }
    };

    _getTabClass() {
        let tabClass = 'unknown';
        switch (this.tabStyle) {
            case 'tabs':
                tabClass = 'tabwrapper';
                break;
            case 'buttons':
                tabClass = 'buttonwrapper';
                break;
            default:
                tabClass = 'linkwrapper';
                break;
        }
        return tabClass;
    }

    _renderTabText = (isActive, index, title) => {
        if (this.tabStyle === 'links') {
            return `
            <sketch-text ${
                isActive && this.tabStyle === 'links'
                    ? 'color="tertiary" variant="BodyCopyBig" data-isactive'
                    : ''
            } data-tabindex="${index + 1}">${title}</sketch-text>`;
        }
        return `
            <span data-tabindex="${index + 1}">${title}</span>`;
    };

    _renderContentSlots = () => {
        let contentSlots = '';
        for (let step = 1; step < Number(this.nrOfSections) + 1; step += 1) {
            const cssClass =
                Number(this.activeSection) === step ? 'active' : '';
            contentSlots = `${contentSlots}<slot name="content-${step}" id="content-${step}" class="${cssClass}"></slot>`;
        }
        return contentSlots;
    };

    _handleTabClick = (tabSlot) => {
        if (tabSlot && !this.freezeActiveTab) {
            if (tabSlot.dataset.isactive === undefined) {
                // non-active slot clicked
                const tabno = tabSlot.dataset.tabindex;
                this.activeSection = tabno;
                this.value = tabno;
                if (!this.fallbackmode) {
                    this.$internals.setFormValue(tabno);
                }
                this.dispatchEvent(
                    new CustomEvent('tabchange', {
                        bubbles: true,
                        composed: true,
                        detail: {
                            activetab: tabno,
                        },
                    })
                );
                this.render();
                this._bind();
            }
        }
    };

    _bind = () => {
        this.$shadowRoot
            .querySelectorAll(
                '.tablinks sketch-text, .tablinks span, .tablinks sketch-button'
            )
            .forEach((slot) => {
                slot.addEventListener('click', () =>
                    this._handleTabClick(slot)
                );
            });
    };

    render() {
        this.$shadowRoot.innerHTML = `
    <style>
    slot{
        display: block;
    }
    .tablinks{
        display: flex;
        flex-direction: ${this.tabsPosition === 'left' ? `column` : `row`};
        margin-bottom: var(--sketchSpacing6);
        background-color: var(--sketchBackgroundColorSection);
        padding: ${this.tabsPosition === 'left' ? `var(--sketchSpacing6)` : `var(--sketchSpacing6) var(--sketchSpacing6) var(--sketchSpacing6) 0`};
        
    }
    ${
        this.tabStyle === 'tabs'
            ? `
    .tablinks{
        padding-bottom: 0;
        border-bottom: 1px solid #cecece;
    }
    .tabwrapper {
        border-radius: var(--sketchBorderRadiusButton);
        padding: var(--sketchSpacing2) var(--sketchSpacing3);
        background-color: var(--sketchColorNeutralLight);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #cecece;
        border-bottom: 0;
    }
    .tabwrapper:hover {
        cursor: pointer;
        background-color: var(--sketchColorPrimaryExtraLight);
    }
    .tabwrapper.active {
        background-color: var(--sketchBackgroundColorPrimary);
        color: var(--sketchColorPrimaryButton);
    }
    `
            : ``
    }
    ${
        this.tabStyle === 'buttons'
            ? `
    .buttonwrapper {
        border-radius: var(--sketchBorderRadiusButton);
        padding: var(--sketchPaddingButton);
        background-color: var(--sketchColorNeutralLight);
        margin-right: var(--sketchSpacing2);
    }
    .buttonwrapper:hover {
        cursor: pointer;
        background-color: var(--sketchColorPrimaryExtraLight);
    }
    .buttonwrapper.active {
        background-color: var(--sketchBackgroundColorPrimary);
        color: var(--sketchColorPrimaryButton);
    }
    .buttonwrapper.active:hover {
        color: var(--sketchColorPrimaryButton);
    }
    `
            : ``
    }
    .linkwrapper {
        margin-right: 1rem;
        display: flex;
        align-items: flex-end;
    }
    ${
        this.tabStyle === 'links'
            ? `
    .tablinks *:hover {
        cursor: pointer;
        color: var(--sketchColorLinkHover);
    }`
            : ``
    }
    
    .tablinks slot.active {
        color: var(--sketchColorLinkHover);
    }
    .linkwrapper.active.fancy {
        // border-image: linear-gradient(0deg,#1095c1 2px,#0000 0) fill 0//0 100 0 0;
        color: var(--sketchColorTextOnTertiary);
        --r: .8em; /* control the cutout */
        border-block: .5em solid #0000;
        padding-inline: .5em calc(var(--r) + .25em);
        line-height: 1.8;
        clip-path: polygon(100% 0,0 0,0 100%,100% 100%,calc(100% - var(--r)) calc(100% - .25em),100% 50%,calc(100% - var(--r)) .25em);
        background:
        radial-gradient(.2em 50% at left,#000a,#0000) border-box,
        var(--sketchColorTertiary) padding-box; /* the color  */
        width: 100%;
    }
    .tablinks slot.active:hover {
        cursor: default;
    }
    .tabcontent {
        ${this.tabsPosition === 'top' && this.tabStyle === 'tabs' ? `padding-top: var(--sketchSpacing6);` : ``}
    }
    .tabcontent slot{
        display: none;
    }
    .tabcontent slot.active{
        display: block;
    }
    @media only screen and (min-width: 768px) {
        ${
            this.tabsPosition === 'left'
                ? `
                .wrapper {
                    display: grid;
                    grid-template-columns: 30% 70%;
                    grid-gap: 1rem;
                }`
                : `.wrapper {
                    display: flex;
                    flex-direction: column;
                }`
        }
        .tablinks{
            margin-bottom: 0;
        }
        .tabcontent {
            width: calc(100% - 1rem);
        }
        .linkwrapper:not(:first-child) {
            padding: var(--sketchSpacing3) 0 var(--sketchSpacing3) var(--sketchSpacing1);
        }
        .linkwrapper:first-child {
            padding: 0 0 var(--sketchSpacing3) var(--sketchSpacing1);
        }
        .tablinks slot {
            display: flex;
            align-items: center;
            padding: .5rem 0;
        }
    }
    </style>
    <div class="wrapper">
        <div class="tablinks">${this._renderTabs()}</div>
        <div class="tabcontent">
        ${this._renderContentSlots()}
        </div>
    </div>`;
    }
}
