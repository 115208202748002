import {isBooleanAttributeTrue} from '../utils/index.js';

export class SketchCheckbox extends HTMLElement {
    static formAssociated = true;

    $checkbox;

    $internals;

    $fallbackmode = false;

    $shadowRoot;

    static get observedAttributes() {
        return [
            'checked',
            'label',
            'fieldId',
            'name',
            'value',
            'groupcontrolled',
            'disabled',
            'required',
            'textType',
            'checkedValue',
            'uncheckedValue',
            'hasinfo',
        ];
    }

    constructor() {
        super();
        try {
            this.$internals = this.attachInternals();
        } catch (error) {
            this.$fallbackmode = true;
        }
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: true,
        });
    }

    get groupcontrolled() {
        return this.getAttribute('groupcontrolled');
    }

    set groupcontrolled(value) {
        this.setAttribute('groupcontrolled', value);
    }

    get checked() {
        return this.getAttribute('checked');
    }

    set checked(value) {
        this.setAttribute('checked', value);
    }

    get value() {
        return this.getAttribute('value');
    }

    set value(value) {
        this.setAttribute('value', value);
    }

    get label() {
        return this.getAttribute('label');
    }

    set label(value) {
        this.setAttribute('label', value);
    }

    get textType() {
        return this.getAttribute('textType');
    }

    set textType(value) {
        this.setAttribute('textType', value);
    }

    get fallbackmode() {
        return isBooleanAttributeTrue(this.getAttribute('fallbackmode'));
    }

    set fallbackmode(value) {
        this.setAttribute('fallbackmode', value);
    }

    get hasinfo() {
        return isBooleanAttributeTrue(this.getAttribute('hasinfo'));
    }

    set hasinfo(value) {
        this.setAttribute('hasinfo', value);
    }

    get fieldId() {
        const fldId = this.getAttribute('fieldId');
        if (fldId !== null && fldId !== undefined) {
            return this.getAttribute('fieldId');
        }
        const name = this.getAttribute('name');
        if (name !== null && name !== undefined) {
            return `check-${name}`;
        }
        return 'empty';
    }

    set fieldId(value) {
        this.setAttribute('fieldId', value);
    }

    get name() {
        return this.getAttribute('name');
    }

    set name(value) {
        this.setAttribute('name', value);
    }

    get checkedValue() {
        return this.getAttribute('checkedValue') || '1';
    }

    set checkedValue(value) {
        this.setAttribute('checkedValue', value);
    }

    get uncheckedValue() {
        return this.getAttribute('uncheckedValue') || '0';
    }

    set uncheckedValue(value) {
        this.setAttribute('uncheckedValue', value);
    }

    get disabled() {
        return isBooleanAttributeTrue(this.getAttribute('disabled'));
    }

    set disabled(value) {
        this.setAttribute('disabled', value);
    }

    get required() {
        return isBooleanAttributeTrue(this.getAttribute('required'));
    }

    set required(value) {
        this.setAttribute('required', value);
    }

    connectedCallback() {
        if (this.$fallbackmode) {
            this.fallbackmode = true;
        }
        this.render();
        this._bind();
        if (!this.groupcontrolled) {
            if (this.value === this.checkedValue) {
                this.checked = true;
            }
            if (!this.fallbackmode) {
                this.$internals.setFormValue(this.value);
                this.$internals.setValidity(
                    this.$checkbox.validity,
                    this.$checkbox.validationMessage,
                    this.$checkbox
                );
            }
        } else {
            if (this.checked) {
                this.$checkbox.checked = true;
            }
        }
    }

    _bind = () => {
        this.$checkbox = this.$shadowRoot.querySelector('input');
        this.$checkbox.addEventListener('change', this._handleChange);
        this._bindInfoClick();
    };

    _unbind = () => {
        this.$checkbox.removeEventListener('change', this._handleChange);
        this._unbindInfoClick();
    };

    _handleChange = (event) => {
        if (this.groupcontrolled) {
            // the checkbox is part of checkboxgroup
            // the checkboxgroup will take care of setting the formvalue, but needs to be notified of checkbox updates
            let eventName = 'sk-checkboxselected';
            if (!event.target.checked) {
                eventName = 'sk-checkboxdeselected';
            }
            const mouseEvent = new MouseEvent(eventName, {
                bubbles: true,
                composed: true,
                value: event.target.value,
            });
            this.dispatchEvent(mouseEvent);
        } else {
            if (!this.fallbackmode) {
                this.$internals.setFormValue(
                    event.target.checked
                        ? this.checkedValue
                        : this.uncheckedValue
                );
                this.$internals.setValidity(
                    this.$checkbox.validity,
                    this.$checkbox.validationMessage,
                    this.$checkbox
                );
            }
            this.value = event.target.checked
                ? this.checkedValue
                : this.uncheckedValue;
            this.checked = event.target.checked;
            const changeEvent = new MouseEvent('change', {
                bubbles: true,
                composed: true,
                value: this.value,
            });
            this.dispatchEvent(changeEvent);
            const skChangeEvent = new MouseEvent('sk-change', {
                bubbles: true,
                composed: true,
                value: this.value,
            });
            this.dispatchEvent(skChangeEvent);
        }
    };

    attributeChangedCallback(property, oldValue, newValue) {
        if (property === 'value') {
            if (!this.fallbackmode) {
                this.$internals.setFormValue(newValue);
            }
        }
        this.render();
        this._bind();
    }

    _isChecked = () => {
        if (!this.groupcontrolled) {
            return this.value === this.checkedValue;
        }
        return this.checked;
    };

    _bindInfoClick = () => {
        const infoIcon = this.$shadowRoot.querySelector(
            '.labelwrapper > sketch-icon'
        );
        if (infoIcon) {
            infoIcon.addEventListener('click', this._handleInfoClick);
        }
    };

    _unbindInfoClick = () => {
        const infoIcon = this.$shadowRoot.querySelector(
            '.labelwrapper > sketch-icon'
        );
        if (infoIcon) {
            infoIcon.removeEventListener('click', this._handleInfoClick);
        }
    };

    _handleInfoClick = () => {
        this.dispatchEvent(
            new MouseEvent('inforequested', {
                bubbles: true,
            })
        );
    };

    render() {
        this.$shadowRoot.innerHTML = `
        <style>
        :host{
            display: flex;
        }
        .labelwrapper {
            display: flex;
        }
        .labelwrapper > sketch-icon {
            margin-left: var(--sketchSpacing2);
            margin-top: 3px;
        }
        .labelwrapper > sketch-icon:hover {
            cursor: pointer;
        }
        input[type="checkbox"]{
            margin: 0 var(--sketchSpacing2) var(--sketchSpacing1) 0;
            font: inherit;
            color: currentColor;
            width: 1.15em;
            height: 1.15em;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;
          }
          
          input[type="checkbox"]::before {
            content: "";
            width: 0.65em;
            height: 0.65em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
          }
          
          input[type="checkbox"]:checked::before {
            transform: scale(1);
          }

        @media only screen and (min-width: 576px) {
            input[type="checkbox"]:hover{
                outline: 1px solid var(--sketchColorNeutral)
            }
            label:hover{
                cursor: pointer;
            }
        }
        </style>
        <input 
            type="checkbox" 
            id="${this.fieldId}" 
            ${this.name ? `name=${this.name}` : ''}
            value="${this.value}" 
            ${this._isChecked() ? 'checked' : ''}
            ${this.required ? 'required' : ''}
            ${this.disabled ? 'disabled' : ''}/>
            <div class="labelwrapper">
                <label for="${this.fieldId}"><sketch-text variant="${
                    this.textType !== undefined && this.textType !== null
                        ? this.texttype
                        : ' footnote'
                }" color=${this.hasError ? 'error' : ''}
                    >${this.label}</sketch-text>
                </label>
                ${this.hasinfo ? '<sketch-icon icon="info" size="xs"></sketch-icon>' : ''}  
            </div>`;
    }
}
