export class SketchSkeletonBox extends HTMLElement {
    static get observedAttributes() {
        return ['variant'];
    }

    constructor() {
        super();
        this._internals = this.attachInternals();
        this.shadow = this.attachShadow({mode: 'open', delegatesFocus: true});
    }

    get variant() {
        return this.getAttribute('variant');
    }

    set variant(value) {
        this.setAttribute('variant', value);
    }

    connectedCallback() {
        this.render();
    }

    attributeChangedCallback(property, oldValue, newValue) {
        this.render();
    }

    render() {
        this.shadow.innerHTML = `
        <style>
            @keyframes shimmer {
              100% {
                transform: translateX(100%);
              }
            }
            :host {
                    display: block;
            }
            .skeleton-box {
              display: inline-block;
              height: 100%;
              width: 100%;
              position: relative;
              overflow: hidden;
              /* background-color: #DDDBDD; */
              background-color: #eeeeee;

              &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transform: translateX(-100%);
                background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
                animation: shimmer 1.5s infinite;
                content: '';
              }
            }
        </style>
        <span class="skeleton-box"></span>`;
    }
}
