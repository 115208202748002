import {isBooleanAttributeTrue} from '../utils/index.js';

export class SketchInputWithButton extends HTMLElement {
    /* for form elements */
    static formAssociated = true;

    $fallbackmode = false;

    $internals = null;

    $shadowRoot;

    $inputField;

    $button;

    static get observedAttributes() {
        return [
            'disabled',
            'hasError',
            'fieldId',
            'fieldlabel',
            'name',
            'placeholder',
            'readonly',
            'required',
            'type',
            'value',
            'maxwidth',
            'max',
            'size',
            'hasinfo',
            'buttonlabel',
            'buttonvalue',
            'buttonvariant',
            'buttonicon',
        ];
    }

    constructor() {
        super();
        /* for form elements */
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: true,
        });
        try {
            this.$internals = this.attachInternals();
        } catch (error) {
            this.$fallbackmode = true;
        }
    }

    get disabled() {
        return isBooleanAttributeTrue(this.getAttribute('disabled'));
    }

    set disabled(value) {
        if (value) {
            this.setAttribute('disabled', '');
        } else {
            this.removeAttribute('disabled');
        }
    }

    get hasError() {
        return isBooleanAttributeTrue(this.getAttribute('hasError'));
    }

    set hasError(value) {
        if (value) {
            this.setAttribute('hasError', '');
        } else {
            this.removeAttribute('hasError');
        }
    }

    get fieldId() {
        return this.getAttribute('fieldId');
    }

    set fieldId(value) {
        this.setAttribute('fieldId', value);
    }

    get fieldlabel() {
        return this.getAttribute('fieldlabel');
    }

    set fieldlabel(value) {
        this.setAttribute('fieldlabel', value);
    }

    get name() {
        return this.getAttribute('name');
    }

    set name(value) {
        this.setAttribute('name', value);
    }

    get placeholder() {
        return this.getAttribute('placeholder');
    }

    set placeholder(value) {
        this.setAttribute('placeholder', value);
    }

    get readonly() {
        return isBooleanAttributeTrue(this.getAttribute('readonly'));
    }

    set readonly(value) {
        if (value) {
            this.setAttribute('readonly', '');
        } else {
            this.removeAttribute('readonly');
        }
    }

    get required() {
        return isBooleanAttributeTrue(this.getAttribute('required'));
    }

    set required(value) {
        if (value) {
            this.setAttribute('required', '');
        } else {
            this.removeAttribute('required');
        }
    }

    get type() {
        return this.getAttribute('type');
    }

    set type(value) {
        this.setAttribute('type', value);
    }

    get value() {
        return this.getAttribute('value');
    }

    set value(value) {
        this.setAttribute('value', value);
    }

    get maxwidth() {
        return this.getAttribute('maxwidth');
    }

    set maxwidth(value) {
        this.setAttribute('maxwidth', value);
    }

    get max() {
        return this.getAttribute('max');
    }

    set max(value) {
        this.setAttribute('max', value);
    }

    get size() {
        return this.getAttribute('size');
    }

    set size(value) {
        this.setAttribute('size', value);
    }

    get hasinfo() {
        return isBooleanAttributeTrue(this.getAttribute('hasinfo'));
    }

    set hasinfo(value) {
        if (value) {
            this.setAttribute('hasinfo', '');
        } else {
            this.removeAttribute('hasinfo');
        }
    }

    get hasbutton() {
        return isBooleanAttributeTrue(this.getAttribute('hasbutton'));
    }

    set hasbutton(value) {
        if (value) {
            this.setAttribute('hasbutton', '');
        } else {
            this.removeAttribute('hasbutton');
        }
    }

    get buttonlabel() {
        return this.getAttribute('buttonlabel');
    }

    set buttonlabel(value) {
        this.setAttribute('buttonlabel', value);
    }

    get buttonvalue() {
        return this.getAttribute('buttonvalue');
    }

    set buttonvalue(value) {
        this.setAttribute('buttonvalue', value);
    }

    get buttonvariant() {
        return this.getAttribute('buttonvariant');
    }

    set buttonvariant(value) {
        this.setAttribute('buttonvariant', value);
    }

    get buttonicon() {
        return this.getAttribute('buttonicon');
    }

    set buttonicon(value) {
        this.setAttribute('buttonicon', value);
    }

    get fallbackmode() {
        return isBooleanAttributeTrue(this.getAttribute('fallbackmode'));
    }

    set fallbackmode(value) {
        this.setAttribute('fallbackmode', value);
    }

    connectedCallback() {
        if (this.$fallbackmode) {
            this.fallbackmode = true;
        }
        this.render();
        this.$inputField = this.$shadowRoot.querySelector('sketch-input');
        this.$button = this.$shadowRoot.querySelector('sketch-button');
        this.$inputField.addEventListener('change', this._handleInputChange);
        this.$inputField.addEventListener('input', this._handleInput);
        this.$inputField.addEventListener('keypress', this._handleKeyPress);
        if (!this.fallbackmode) {
            this.$internals.setFormValue(this.value);
        }
    }

    _updateValue = (value) => {
        this.value = value;
        if (!this.fallbackmode) {
            this.$internals.setFormValue(value);
        }
    };

    _handleInputChange = (event) => {
        this._updateValue(event.detail.value);
    };

    _handleInput = (event) => {
        this._updateValue(event.target.shadowRoot.querySelector('input').value);
    };

    _handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            const clickEvent = new MouseEvent('sk-click', {
                bubbles: true,
                composed: true,
            });
            this.dispatchEvent(clickEvent);
        }
    };

    attributeChangedCallback(property, oldValue, newValue) {
        if (oldValue !== newValue) {
            switch (property) {
                case 'disabled':
                    this.$inputField.setAttribute(
                        'disabled',
                        isBooleanAttributeTrue(newValue)
                    );
                    this.$button.setAttribute(
                        'disabled',
                        isBooleanAttributeTrue(newValue)
                    );
                    break;
                case 'hasError':
                case 'fieldId':
                case 'fieldlabel':
                case 'name':
                case 'placeholder':
                case 'readonly':
                case 'required':
                case 'type':
                case 'value':
                    this.$inputField?.setAttribute('value', newValue);
                    if (!this.fallbackmode) {
                        this.$internals.setFormValue(newValue);
                    }
                    break;
                case 'maxwidth':
                case 'max':
                case 'size':
                case 'hasinfo':
                case 'buttonlabel':
                case 'buttonvalue':
                case 'buttonvariant':
                case 'buttonicon':
                    this[property] = newValue;
                    break;
                default:
                    break;
            }
        }
    }

    render() {
        this.$shadowRoot.innerHTML = `
        <style>
        :host {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
        }
        </style>
        <sketch-input 
            label="${this.fieldlabel}"
            size="${this.size}"
            ${this.disabled ? 'disabled' : ''}
            ${this.hasError ? 'hasError' : ''}
            ${this.readonly ? 'readonly' : ''}
            ${this.required ? 'required' : ''}
            ${this.hasinfo ? 'hasinfo' : ''}
            fieldId="${this.fieldId}"
            name="${this.name}"
            placeholder="${this.placeholder || ''}"
            type="${this.type}"
            value="${this.value || ''}"
            ${this.maxwidth ? `maxwidth="${this.maxwidth}"` : ''}
            ${this.max ? `max="${this.max}"` : ''}
            hasbutton 
            nomargin>
        </sketch-input>
        <sketch-button 
            size="inputfield" 
            ispartoffield 
            ${this.disabled ? 'disabled' : ''}
            ${this.buttonicon ? `icon="${this.buttonicon}" iconPosition="after"` : ''}
            ${this.buttonlabel ? `label="${this.buttonlabel}"` : ''}
            ${this.buttonvalue ? `value="${this.buttonvalue}"` : ''}
            ${this.buttonvariant ? `variant="${this.buttonvariant}"` : ''}
            >
        </sketch-button>`;
    }
}
