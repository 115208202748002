import {isBooleanAttributeTrue} from '../utils/index.js';

export class SketchPagination extends HTMLElement {
    static get observedAttributes() {
        return [
            'buttonsize',
            'currentpage',
            'displayallpages',
            'totalpages',
            'variant',
            'visibledelta',
            'disabledpages',
        ];
    }

    constructor() {
        super();
        this.shadow = this.attachShadow({mode: 'open', delegatesFocus: true});
    }

    get variant() {
        return this.getAttribute('variant') || 'numbered';
    }

    set variant(value) {
        this.setAttribute('variant', value);
    }

    get displayallpages() {
        return isBooleanAttributeTrue(this.getAttribute('displayallpages'));
    }

    set displayallpages(value) {
        this.setAttribute('displayallpages', value);
    }

    get currentpage() {
        if (this.variant === 'alfabetical') {
            return this.getAttribute('currentpage');
        }
        return parseInt(this.getAttribute('currentpage'));
    }

    set currentpage(value) {
        if (this.variant === 'alfabetical') {
            this.setAttribute('currentpage', value);
        } else {
            this.setAttribute('currentpage', parseInt(value));
        }
    }

    get totalpages() {
        return parseInt(this.getAttribute('totalpages'));
    }

    set totalpages(value) {
        this.setAttribute('totalpages', value);
    }

    get visibledelta() {
        return parseInt(this.getAttribute('visibledelta')) || 2;
    }

    set visibledelta(value) {
        this.setAttribute('visibledelta', value);
    }

    get buttonsize() {
        return parseInt(this.getAttribute('buttonsize')) || 40;
    }

    set buttonsize(value) {
        this.setAttribute('buttonsize', value);
    }

    get disabledpages() {
        return this.getAttribute('disabledpages')
            ? this.getAttribute('disabledpages').split(';')
            : [];
    }

    set disabledpages(value) {
        this.setAttribute('disabledpages', value);
    }

    connectedCallback() {
        this.render();
        this._bind();
    }

    attributeChangedCallback(property, oldValue, newValue) {
        if (oldValue !== newValue) {
            if (property === 'currentpage') {
                this._unbind();
                this.render();
                this._bind();
            }
        }
    }

    _renderPageNumbers = () => {
        const totalPages = this.totalpages;
        const currentPage = this.currentpage;
        const pageNumbers = [];

        if (this.displayallpages) {
            for (let i = 1; i <= totalPages; i += 1) {
                pageNumbers.push(i);
            }
        } else {
            const totalVisible = this.visibledelta * 2 + 5;
            const startPage = Math.max(1, currentPage - this.visibledelta);
            const endPage = Math.min(
                totalPages,
                currentPage + this.visibledelta
            );

            if (startPage > 1) {
                pageNumbers.push(1);
                if (startPage > 2) {
                    pageNumbers.push('...');
                }
            }

            for (let i = startPage; i <= endPage; i += 1) {
                pageNumbers.push(i);
            }

            if (endPage < totalPages) {
                if (endPage < totalPages - 1) {
                    pageNumbers.push('...');
                }
                pageNumbers.push(totalPages);
            }
        }

        return pageNumbers
            .map((pageNumber) => {
                if (pageNumber === '...') {
                    return `<button class="hiddenpage">${pageNumber}</button>`;
                }
                if (pageNumber === this.currentpage) {
                    return `<button class="activepage">${pageNumber}</button>`;
                }
                return `<button class="pagebutton" data-value="${pageNumber}">${pageNumber}</button>`;
            })
            .reduce((prev, curr) => `${prev}${curr}`, '');
    };

    _bind = () => {
        this.shadow.querySelectorAll('.pagebutton').forEach((button) => {
            button.addEventListener('click', this._handlePageChange);
        });

        this.shadow
            .querySelector('.iconbutton.previous:not(.disabled)')
            ?.addEventListener('click', this._handlePrevious);
        this.shadow
            .querySelector('.iconbutton.next:not(.disabled)')
            ?.addEventListener('click', this._handleNext);
    };

    _handlePageChange = (event) => {
        this.currentpage = event.target.getAttribute('data-value');
        this._dispatchPageChange();
    };

    _handlePrevious = () => {
        this.currentpage -= 1;
        this._dispatchPageChange();
    };

    _handleNext = () => {
        this.currentpage += 1;
        this._dispatchPageChange();
    };

    _dispatchPageChange = () => {
        this.dispatchEvent(
            new CustomEvent('pagechange', {
                detail: {page: this.currentpage},
            })
        );
    };

    _unbind = () => {
        this.shadow.querySelectorAll('.pagebutton').forEach((button) => {
            button.removeEventListener('click', this._handlePageChange);
        });
    };

    _renderPreviousButton = () =>
        `<button class="iconbutton previous ${this.currentpage === 1 ? 'disabled' : ''}"><sketch-icon icon="angle-left"></sketch-icon></button>`;

    _renderNextButton = () =>
        `<button class="iconbutton next ${this.currentpage === this.totalpages ? 'disabled' : ''}"><sketch-icon icon="angle-right"></sketch-icon></button>`;

    _renderAlphaButtons = () => {
        const alphabet = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
        return alphabet
            .map(
                (letter) =>
                    `<button 
                        class="${this.currentpage?.toUpperCase() === letter ? 'activepage' : 'pagebutton'}" 
                        data-value="${letter}"
                        ${this.disabledpages.includes(letter) ? 'disabled' : ''} >
                        ${letter}
                    </button>`
            )
            .reduce((prev, curr) => `${prev}${curr}`, '');
    };

    render() {
        this.shadow.innerHTML = `
        <style>
        .pagination {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: var(--sketchSpacingPaginationButton,var(--sketchSpacing1));
        }
        .hiddenpage,
        .pagebutton,
        .iconbutton,
        .activepage {
            height:${this.buttonsize}px;
            width:${this.buttonsize}px;
            display:flex;
            justify-content:center;
            align-items:center;
            border: var(--sketchBorderPaginationButton, 1px solid grey);
        }
        .hiddenpage {
            border:none;
            background-color: transparent;
        }
        .activepage {
            background-color: var(--sketchBackgroundColorPaginationButtonCurrent,var(--sketchBackgroundColorButtonPrimary));
            color: var(--sketchColorPaginationButtonCurrent,var(--sketchColorTextOnButtonPrimary,var(--sketchColorTextOnPrimary)));
        }

        .iconbutton {
            background-color: var(--sketchBackgroundColorPaginationNavButton);
            color: var(--sketchColorPaginationNavButton);
            height: ${this.buttonsize}px;
        }
        .iconbutton:not(.disabled):hover {
            cursor: pointer;
            background-color: var(--sketchBackgroundColorPaginationNavButtonHover);
            color: var(--sketchColorPaginationNavButtonHover);
        }
        .iconbutton.disabled {
            background-color: var(--sketchBackgroundColorPaginationNavButtonDisabled);
            color: var(--sketchColorPaginationNavButtonDisabled);
            border-color: var(--sketchColorPaginationNavButtonDisabled);
        }

        .pagebutton {
            background-color: var(--sketchBackgroundColorPaginationButton);
            color: var(--sketchColorPaginationButton);
        }
        .pagebutton:not(:disabled):hover {
            cursor: pointer;
            background-color: var(--sketchBackgroundColorPaginationButtonHover);
            color: var(--sketchColorPaginationButtonHover);
        }
        .pagebutton:disabled {
            background-color: var(--sketchBackgroundColorPaginationNavButtonDisabled);
            color: var(--sketchColorPaginationNavButtonDisabled);
            border-color: var(--sketchColorPaginationNavButtonDisabled);
        }
        </style>
        <div class="pagination">
            ${
                this.variant === 'numbered'
                    ? `
                    ${this._renderPreviousButton()}
                    ${this._renderPageNumbers()}
                    ${this._renderNextButton()}`
                    : `${this._renderAlphaButtons()}`
            }
            
        </div>`;
    }
}
