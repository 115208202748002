import {isBooleanAttributeTrue} from '../utils/index.js';

export class SketchCheckboxgroup extends HTMLElement {
    static formAssociated = true;

    $internals;

    $fallbackmode = false;

    $shadowRoot;

    _values;

    static get observedAttributes() {
        return [
            'fieldId',
            'variant',
            'label',
            'value',
            'name',
            'delimiter',
            'hasinfo',
            'disabled',
            'subgrid',
        ];
    }

    constructor() {
        super();
        try {
            this.$internals = this.attachInternals();
        } catch (error) {
            this.$fallbackmode = true;
        }
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: true,
        });
        this._values = this.value ? this.value.split(this.delimiter) : [];
    }

    get value() {
        return this.getAttribute('value');
    }

    set value(value) {
        this.setAttribute('value', value);
    }

    get label() {
        return this.getAttribute('label');
    }

    set label(value) {
        this.setAttribute('label', value);
    }

    get variant() {
        return this.getAttribute('variant');
    }

    set variant(value) {
        this.setAttribute('variant', value);
    }

    get name() {
        return this.getAttribute('name');
    }

    set name(value) {
        this.setAttribute('name', value);
    }

    get fieldId() {
        return this.getAttribute('fieldId');
    }

    set fieldId(value) {
        this.setAttribute('fieldId', value);
    }

    get disabled() {
        return isBooleanAttributeTrue(this.getAttribute('disabled'));
    }

    set disabled(value) {
        this.setAttribute('disabled', value);
    }

    get hasinfo() {
        return isBooleanAttributeTrue(this.getAttribute('hasinfo'));
    }

    set hasinfo(value) {
        this.setAttribute('hasinfo', value);
    }

    get delimiter() {
        const delim = this.getAttribute('delimiter');
        return delim || ';';
    }

    set delimiter(value) {
        this.setAttribute('delimiter', value);
    }

    get subgrid() {
        return isBooleanAttributeTrue(this.getAttribute('subgrid'));
    }

    set subgrid(value) {
        this.setAttribute('subgrid', value);
    }

    get fallbackmode() {
        return isBooleanAttributeTrue(this.getAttribute('fallbackmode'));
    }

    set fallbackmode(value) {
        this.setAttribute('fallbackmode', value);
    }

    connectedCallback() {
        if (this.$fallbackmode) {
            this.fallbackmode = true;
        }
        this.render();
        this._bind();
        if (!this.fallbackmode) {
            this.$internals.setFormValue(this.value);
        }
        this.$shadowRoot.addEventListener('slotchange', () => {
            const nodes = this.querySelectorAll('sketch-checkbox');
            for (const node of nodes) {
                // set groupcontrolled attribute on slotted checkboxes
                if (this._values.includes(node.getAttribute('value'))) {
                    node.setAttribute('checked', true);
                } else {
                    node.removeAttribute('checked');
                }
                node.setAttribute('groupcontrolled', true);
                if (this.disabled) {
                    node.setAttribute('disabled', true);
                }
            }
        });
    }

    attributeChangedCallback(property, oldValue, newValue) {
        this._unbindInfoClick();
        this.render();
        this._bindInfoClick();
        if (property === 'value' || property === 'delimiter') {
            if (this.delimiter) {
                this._values = this.value
                    ? this.value.split(this.delimiter)
                    : [];
            }
        }
    }

    _bind = () => {
        this.$shadowRoot.addEventListener('sk-checkboxselected', (event) => {
            this._addSelection(event.target.value);
        });
        this.$shadowRoot.addEventListener('sk-checkboxdeselected', (event) => {
            this._removeSelection(event.target.value);
        });
        this._bindInfoClick();
    };

    _handleInfoClick = () => {
        this.dispatchEvent(
            new MouseEvent('inforequested', {
                bubbles: true,
            })
        );
    };

    _bindInfoClick = () => {
        const infoIcon = this.$shadowRoot.querySelector('label > sketch-icon');
        if (infoIcon) {
            infoIcon.addEventListener('click', this._handleInfoClick);
        }
    };

    _unbindInfoClick = () => {
        const infoIcon = this.$shadowRoot.querySelector('label > sketch-icon');
        if (infoIcon) {
            infoIcon.removeEventListener('click', this._handleInfoClick);
        }
    };

    _addSelection = (value) => {
        this._values.push(value);
        this.value = this._values.join(this.delimiter);
        if (!this.fallbackmode) {
            this.$internals.setFormValue(this.value);
        }
        this._triggerChangeEvent(this.value);
    };

    _removeSelection = (value) => {
        this._values = this._values.filter((val) => val !== value);
        this.value = this._values.join(this.delimiter);
        if (!this.fallbackmode) {
            this.$internals.setFormValue(this.value);
        }
        this._triggerChangeEvent(this.value);
    };

    _triggerChangeEvent = (value) => {
        this.dispatchEvent(
            new CustomEvent('change', {
                bubbles: true,
                detail: {
                    value,
                },
            })
        );
    };

    render() {
        this.$shadowRoot.innerHTML = `
        <style>
        :host {
            display: flex;
            flex-direction: column;
            grid-column: span 2;
        }
        label {
            margin-bottom: var(--sketchSpacing2);
            ${this.hasinfo ? 'display: flex;' : ''}
            ${this.hasinfo && this.variant !== 'vertical' ? 'align-items: center;' : ''}
        }
        label > sketch-icon {
            height: fit-content;
            margin-left: var(--sketchSpacing2);
        }
        label > sketch-icon:hover {
            cursor: pointer;
        }
        .checkboxes {
            display: flex;
            flex-direction: ${this.variant === 'vertical' ? 'column' : 'row'};
            flex-wrap: wrap;
        }
        .checkboxes slot::slotted(*) {
            ${
                this.variant === 'vertical'
                    ? 'margin-bottom: var(--sketchSpacing2);'
                    : 'margin-right: var(--sketchSpacing2);'
            }
            
        }
        @media only screen and (min-width: 576px) {
            :host {
                ${
                    this.subgrid
                        ? `display: grid;
                grid-template-columns: subgrid;`
                        : ''
                }
            }
        }
        </style>
        <label>
            <sketch-text variant="Label">${this.label}</sketch-text>
            ${this.hasinfo ? '<sketch-icon icon="info" size="xs"></sketch-icon>' : ''}
        </label>
        <div class="checkboxes"><slot></slot></div>`;
    }
}
