import {isBooleanAttributeTrue, remToPx} from '../utils/index.js';

export class SketchButton extends HTMLElement {
    static formAssociated = true;

    $internals = null;

    $fallbackmode = false;

    $shadowRoot;

    $loaderwrapper;

    $button;

    $icon;

    static get observedAttributes() {
        return [
            'disabled',
            'id',
            'label',
            'name',
            'size',
            'value',
            'variant',
            'submit',
            'extraStyle',
            'icon',
            'iconPosition',
            'noEndRounding',
            'noStartRounding',
            'isloading',
            'hasloading',
        ];
    }

    constructor() {
        super();
        try {
            this.$internals = this.attachInternals();
        } catch (error) {
            this.$fallbackmode = true;
        }
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: true,
        });
    }

    get disabled() {
        return isBooleanAttributeTrue(this.getAttribute('disabled'));
    }

    set disabled(value) {
        this.setAttribute('disabled', value);
    }

    get label() {
        return this.getAttribute('label') || '';
    }

    get id() {
        const id = this.getAttribute('id');
        if (id !== null && id !== undefined) {
            return id;
        }
        const name = this.getAttribute('name');
        if (name !== null && name !== undefined) {
            return `btn-${name}`;
        }

        return 'empty';
    }

    get name() {
        return this.getAttribute('name');
    }

    get size() {
        if (
            this.getAttribute('size') !== null &&
            this.getAttribute('size') !== undefined
        ) {
            return this.getAttribute('size');
        }
        return 'md';
    }

    get value() {
        return this.getAttribute('value');
    }

    get variant() {
        return this.getAttribute('variant') || 'default';
    }

    get submit() {
        return this.getAttribute('submit');
    }

    set submit(value) {
        this.setAttribute('submit', value);
    }

    get extraStyle() {
        return this.getAttribute('extraStyle');
    }

    set extraStyle(value) {
        this.setAttribute('extraStyle', value);
    }

    get icon() {
        return this.getAttribute('icon');
    }

    set icon(value) {
        this.setAttribute('icon', value);
    }

    get iconPosition() {
        return this.getAttribute('iconPosition') || 'before';
    }

    set iconPosition(value) {
        this.setAttribute('iconPosition', value);
    }

    get noEndRounding() {
        return isBooleanAttributeTrue(this.getAttribute('noEndRounding'));
    }

    set noEndRounding(value) {
        this.setAttribute('noEndRounding', value);
    }

    get isloading() {
        return isBooleanAttributeTrue(this.getAttribute('isloading'));
    }

    set isloading(value) {
        this.setAttribute('isloading', value);
    }

    get hasloading() {
        return isBooleanAttributeTrue(this.getAttribute('hasloading'));
    }

    set hasloading(value) {
        this.setAttribute('hasloading', value);
    }

    get noStartRounding() {
        return isBooleanAttributeTrue(this.getAttribute('noStartRounding'));
    }

    set noStartRounding(value) {
        this.setAttribute('noStartRounding', value);
    }

    get fallbackmode() {
        return isBooleanAttributeTrue(this.getAttribute('fallbackmode'));
    }

    set fallbackmode(value) {
        this.setAttribute('fallbackmode', value);
    }

    connectedCallback() {
        if (this.$fallbackmode) {
            this.fallbackmode = true;
        }
        this.render();
        this._bind();
    }

    _bind = () => {
        this.$button = this.$shadowRoot.querySelector('button');
        if (this.$button) {
            this.$button.addEventListener('click', this._dispatchClick);
        }
        this.$icon = this.$shadowRoot.querySelector('sketch-icon');
        this.$loaderwrapper = this.$shadowRoot.querySelector('.loaderwrapper');
    };

    attributeChangedCallback(property, oldValue, newValue) {
        if (property !== 'isloading') {
            this.render();
            this._bind();
        } else {
            if (
                this.isloading &&
                !this.$loaderwrapper?.classList.contains('loading')
            ) {
                this.$loaderwrapper?.classList.add('loading');
                this.$button.setAttribute('disabled', true);
                this.$icon?.classList.add('hidden');
            }
            if (!this.isloading) {
                this.$loaderwrapper?.classList.remove('loading');
                this.$button.removeAttribute('disabled');
                this.$icon?.classList.remove('hidden');
            }
        }
    }

    _dispatchClick = () => {
        if (this._isSubmit() && !this.fallbackmode) {
            if (this.$internals.form && this.$internals.form.reportValidity()) {
                this.$internals.form.submit();
            }
        } else {
            const event = new MouseEvent('sk-click', {
                bubbles: true,
                composed: true,
            });
            const clickEvent = new MouseEvent('click', {
                bubbles: true,
                composed: true,
            });
            this.dispatchEvent(event);
            this.dispatchEvent(clickEvent);
        }
    };

    _isSubmit = () => this.submit !== undefined && this.submit != null;

    _getIconMargin = () => {
        if (this.iconPosition === 'before') {
            return '0 var(--sketchSpacing2) 0 0';
        }
        if (this.iconPosition === 'after') {
            return '0 0 0 var(--sketchSpacing2)';
        }
        return '0 var(--sketchSpacing2) 0 0';
    };

    _renderButtonContent = () => {
        let content = '';
        if (!this.icon) {
            content = `<div class="loaderwrapper ${this.isloading ? 'loading' : ''}"><sketch-loader size="${this.size}"></sketch-loader></div>`;
        }
        if (this.icon && this.iconPosition === 'before') {
            content = `${content}<sketch-icon class="${this.isloading ? 'hidden' : ''}" icon="${this.icon}" size="${this.size}" style="margin: ${this.label !== '' ? this._getIconMargin() : 0};"></sketch-icon><div class="loaderwrapper ${this.isloading ? 'loading' : ''}"><sketch-loader size="${this.size}"></sketch-loader></div>`;
        }
        content = `${content}${this.label}`;
        if (this.icon && this.iconPosition === 'after') {
            content = `${content}<sketch-icon class="${this.isloading ? 'hidden' : ''}" icon="${this.icon}" size="${this.size}" style="margin: ${this.label !== '' ? this._getIconMargin() : 0};"></sketch-icon><div class="loaderwrapper ${this.isloading ? 'loading' : ''}"><sketch-loader size="${this.size}"></sketch-loader></div>`;
        }
        return content;
    };

    _getLoaderWidth = () => {
        const fontRootSize = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        let size = 40;
        switch (this.getAttribute('size')) {
            case 'xs':
                size = remToPx(0.8, fontRootSize);
                break;
            case 'sm':
                size = remToPx(1, fontRootSize);
                break;
            case 'md':
                size = remToPx(1.2, fontRootSize);
                break;
            case 'lg':
                size = remToPx(2, fontRootSize);
                break;
            case 'xl':
                size = remToPx(4, fontRootSize);
                break;
            default:
                try {
                    size = Number(this.getAttribute('size'));
                } catch (e) {
                    size = 40;
                } finally {
                    if (size === 0) {
                        size = remToPx(1.2, fontRootSize);
                    }
                    if (Number.isNaN(size)) {
                        size = 40;
                    }
                }
                break;
        }
        return size;
    };

    render() {
        const cssClass = `sketch-button--${this.variant} size--${this.size}`;
        this.$shadowRoot.innerHTML = `
        <style>
        :host {
            width: fit-content;
        }
        button {
            display: block;
            border: solid 1px gray;
            padding: var(--sketchPaddingButton);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: var(--sketchBorderRadiusButton);
            ${this.noEndRounding ? 'border-top-right-radius: 0;border-bottom-right-radius: 0;' : ''}
            ${this.noStartRounding ? 'border-top-left-radius: 0;border-bottom-left-radius: 0;' : ''}
            transition: all .3s ease;
        }
        button:hover {
            cursor: pointer;
        }
        button[disabled]:hover {
            cursor: default;
        }
            sketch-icon.hidden {
                display: none;
            }
        button.sketch-button--primary {
            background-color: var(--sketchBackgroundColorButtonPrimary);
            color: var(--sketchColorTextOnButtonPrimary, var(--sketchColorTextOnPrimary));
            border: var(--sketchBorderButtonPrimary, none);
            padding: var(--sketchPaddingButtonPrimary, var(--sketchPaddingButton));
            border-radius: var(--sketchBorderRadiusButtonPrimary, var(--sketchBorderRadiusButton));
        }
        button.sketch-button--primary:hover {
            background-color: var(--sketchBackgroundColorButtonPrimaryHover, var(--sketchBackgroundColorPrimaryHover));
            color: var(--sketchColorTextOnButtonPrimaryHover, var(--sketchColorPrimaryButtonHover));
            border: var(--sketchBorderButtonPrimaryHover, none)
        }
        button[disabled].sketch-button--primary {
            background-color: var(--sketchBackgroundColorButtonPrimaryDisabled);
            color: var(--sketchColorTextOnButtonPrimaryDisabled, var(--sketchColorTextOnPrimaryDisabled));
            border: var(--sketchBorderButtonPrimaryDisabled, none)
        }

        button.sketch-button--default,
        button.sketch-button--secondary {
            background-color: var(--sketchBackgroundColorButtonSecondary);
            color: var(--sketchColorTextOnButtonSecondary, var(--sketchColorSecondaryButton));
            border: var(--sketchBorderButtonSecondary, none);
            padding: var(--sketchPaddingButtonSecondary, var(--sketchPaddingButton));
            border-radius: var(--sketchBorderRadiusButtonSecondary, var(--sketchBorderRadiusButton));
        }
        button.sketch-button--default:hover,
        button.sketch-button--secondary:hover {
            background-color: var(--sketchBackgroundColorButtonSecondaryHover);
            color: var(--sketchColorTextOnButtonSecondaryHover, var(--sketchColorSecondaryButtonHover));
            border: var(--sketchBorderButtonSecondaryHover, none);
        }
        button[disabled].sketch-button--default,
        button[disabled].sketch-button--secondary {
            background-color: var(--sketchBackgroundColorButtonSecondaryDisabled);
            color: var(--sketchColorTextOnButtonSecondaryDisabled, var(--sketchColorTextOnSecondaryDisabled));
            border: var(--sketchBorderButtonSecondaryDisabled, none);
        }

        button.sketch-button--tertiary {
            background-color: var(--sketchBackgroundColorButtonTertiary, var(--sketchBackgroundColorTertiary));
            color: var(--sketchColorTextOnButtonTertiary, var(--sketchColorTextOnTertiary));
            border: var(--sketchBorderButtonTertiary, none);
            padding: var(--sketchPaddingButtonTertiary, var(--sketchPaddingButton));
            border-radius: var(--sketchBorderRadiusButtonTertiary, var(--sketchBorderRadiusButton));
        }
        button.sketch-button--tertiary:hover {
            background-color: var(--sketchBackgroundColorButtonTertiaryHover, var(--sketchBackgroundColorTertiaryHover));
            color: var(--sketchColorTextOnButtonTertiaryHover, var(--sketchColorTextOnTertiary));
            border: var(--sketchBorderButtonTertiaryHover, none);
        }
        button[disabled].sketch-button--tertiary {
            background-color: var(--sketchBackgroundColorButtonTertiaryDisabled);
            color: var(--sketchColorTextOnButtonTertiaryDisabled, var(--sketchColorTextOnTertiaryDisabled));
            border: var(--sketchBorderButtonTertiaryDisabled, none);
        }

        .size--sm {
            height: 24px;
            padding: 4px 10px;
            font-size: calc(var(--sketchFontSizeButton, var(--sketchFontSizeDefault)) * 0.7);
        }
        .size--md {
            /*height: 32px;*/
            padding: var(--sketchPaddingButton);
            font-size: var(--sketchFontSizeButton, var(--sketchFontSizeDefault));
        }
        .size--lg {
            height: 48px;
            padding: 30px 60px;
            font-size: calc(var(--sketchFontSizeButton, var(--sketchFontSizeDefault)) * 1.2);
            font-weight: bold;
        }
        button .loaderwrapper {
            width: 0px;
            transition: width 0.2s ease-in-out;
            
            sketch-loader {
                ${this.variant === 'primary' ? '--sketchColorPrimary: var(--sketchColorTextOnButtonPrimaryDisabled, var(--sketchColorTextOnPrimaryDisabled));' : ''}
                ${this.variant === 'default' ? '--sketchColorPrimary: var(--sketchColorTextOnButtonSecondaryDisabled, var(--sketchColorTextOnSecondaryDisabled));' : ''}
                ${this.variant === 'tertiary' ? '--sketchColorPrimary: var(--sketchColorTextOnButtonTertiaryDisabled, var(--sketchColorTextOnTertiaryDisabled));' : ''}
                
                display:none;
            }
        }
        button .loaderwrapper.loading {
            margin: ${this.label !== '' ? this._getIconMargin() : 0};
            width: ${this._getLoaderWidth()}px;

                sketch-loader {
                   display:block;
                }
            }

        </style>
        <button
            class="${cssClass} ${this.extraCssClass}"
            style="${this.extraStyle}"
            ${this.disabled || this.isloading ? 'disabled' : ''}
            type="${this._isSubmit() ? 'submit' : 'button'}"
            ${this.name ? `name="${this.name}"` : ''}
            ${this.value ? `value="${this.value}"` : ''}
            ${this.id ? `id="${this.id}"` : ''}
        >
        ${this._renderButtonContent()}</button>`;
    }
}
