export class SketchText extends HTMLElement {
    constructor() {
        super();
        this.shadow = this.attachShadow({mode: 'open', delegatesFocus: true});
    }

    static get observedAttributes() {
        return ['variant', 'color', 'spacing', 'spacingType'];
    }

    set spacingType(value) {
        this.setAttribute('spacingType', value);
    }

    get spacingType() {
        return this.getAttribute('spacingType');
    }

    set spacing(value) {
        this.setAttribute('spacing', value);
    }

    get spacing() {
        return this.getAttribute('spacing');
    }

    set variant(value) {
        this.setAttribute('variant', value);
    }

    get variant() {
        return this.getAttribute('variant');
    }

    set color(value) {
        this.setAttribute('color', value);
    }

    get color() {
        return this.getAttribute('color');
    }

    connectedCallback() {
        this.render();
    }

    get margin() {
        let styling = '';
        if (this.spacing) {
            styling = `margin-bottom: var(--sketchSpacing${this.spacing});`;
            if (this.spacingType === 'top') {
                styling = `margin-top: var(--sketchSpacing${this.spacing});`;
            }
            if (this.spacingType === 'vertical') {
                styling = `margin-top: var(--sketchSpacing${this.spacing});margin-bottom: var(--sketchSpacing${this.spacing});`;
            }
        }
        return styling;
    }

    render() {
        const spacingvar = this.margin;
        this.shadow.innerHTML = `
        <style>
        @media print {
            .text {
                color: red;
            }
        }
        :host{
            ${spacingvar ? 'display: block;' : ''}
            ${spacingvar || ''}
        }
        .text {
            display: block;
            color: var(--sketchColorText);
            font-size: var(--sketchFontSizeBodyCopy, var(--sketchFontSizeDefault, 1rem));
            font-family: var(--sketchFontFamily);
            font-weight: var(--sketchFontWeightBodyCopy, var(--sketchFontWeight));
            font-style: var(--sketchFontStyleBodyCopy, normal);
            }
        .text.BodyCopyBold{
            font-size: var(--sketchFontSizeBodyCopyBold, var(--sketchFontSizeDefault, 1rem));
            font-weight: var(--sketchFontWeightBodyCopyBold, bold);
            font-style: var(--sketchFontStyleBodyCopyBold, normal)
        }
        .text.BodyCopyBig {
            font-size: var(--sketchFontSizeBodyCopyBig, 1.2rem);
            font-weight: var(--sketchFontWeightBodyCopyBig, normal);
            font-style: var(--sketchFontStyleBodyCopyBig, normal)
        }
        .text.BodyCopyBigbold {
            font-size: 1.2rem;
            font-weight: 700;
        }
        .text.Label {
            font-size: var(--sketchFontSizeLabel, .8rem);
            font-weight: var(--sketchFontWeightLabel, normal);
            font-style: var(--sketchFontStyleLabel, normal)
        }
        .text.Footnote {
            font-size: var(--sketchFontSizeFootnote, .8rem);
            font-weight: var(--sketchFontWeightFootnote, normal);
            font-style: var(--sketchFontStyleFootnote, italic)
        }
        .text.fieldnote {
            font-size: 0.7rem;
            letter-spacing: 0.2px;
        }
        .text.primary {
            color: var(--sketchColorPrimary);
        }
        .text.error {
            color: var(--sketchColorError);
        }
        .text.success {
            color: var(--sketchColorSuccess);
        }
        .text.warning {
            color: var(--sketchColorWarning);
        }
        </style>
        <span class="text ${this.variant} ${this.color}">
            <slot></slot>
        </span>`;
    }
}
