import styles from './input.style.css';
import {isBooleanAttributeTrue} from '../utils/index.js';

export class SketchSelect extends HTMLElement {
    static formAssociated = true;

    $select;

    $internals;

    $fallbackmode = false;

    $shadowRoot;

    $options = [];

    static get observedAttributes() {
        return [
            'disabled',
            'fieldId',
            'label',
            'hasError',
            'name',
            'readonly',
            'required',
            'nomargin',
            'subgrid',
            'hasinfo',
            'value',
        ];
    }

    constructor() {
        super();
        try {
            this.$internals = this.attachInternals();
        } catch (error) {
            this.$fallbackmode = true;
        }
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: true,
        });
    }

    get name() {
        return this.getAttribute('name');
    }

    set name(value) {
        this.setAttribute('name', value);
    }

    get label() {
        return this.getAttribute('label');
    }

    set label(value) {
        this.setAttribute('label', value);
    }

    get fieldId() {
        return this.getAttribute('fieldId');
    }

    set fieldId(value) {
        this.setAttribute('fieldId', value);
    }

    get hasinfo() {
        return isBooleanAttributeTrue(this.getAttribute('hasinfo'));
    }

    set hasinfo(value) {
        this.setAttribute('hasinfo', value);
    }

    get disabled() {
        return isBooleanAttributeTrue(this.getAttribute('disabled'));
    }

    set disabled(value) {
        this.setAttribute('disabled', value);
    }

    get hasError() {
        return isBooleanAttributeTrue(this.getAttribute('hasError'));
    }

    set hasError(value) {
        this.setAttribute('hasError', value);
    }

    get nomargin() {
        return isBooleanAttributeTrue(this.getAttribute('nomargin'));
    }

    set nomargin(value) {
        this.setAttribute('nomargin', value);
    }

    get readonly() {
        return isBooleanAttributeTrue(this.getAttribute('readonly'));
    }

    set readonly(value) {
        this.setAttribute('readonly', value);
    }

    get required() {
        return isBooleanAttributeTrue(this.getAttribute('required'));
    }

    set required(value) {
        this.setAttribute('required', value);
    }

    get subgrid() {
        return isBooleanAttributeTrue(this.getAttribute('subgrid'));
    }

    set subgrid(value) {
        this.setAttribute('subgrid', value);
    }

    get fallbackmode() {
        return isBooleanAttributeTrue(this.getAttribute('fallbackmode'));
    }

    set fallbackmode(value) {
        this.setAttribute('fallbackmode', value);
    }

    get value() {
        return this.getAttribute('value');
    }

    set value(value) {
        this.setAttribute('value', value);
    }

    connectedCallback() {
        let selectedOptionValue = null;
        if (this.$fallbackmode) {
            this.fallbackmode = true;
        }
        this.render();
        if (!this.fallbackmode) {
            this.$internals.setFormValue(this.value || '');
        }
        this._insertSelectFromTemplate();
        this.$select = this.$shadowRoot.querySelector('select');
        this.$shadowRoot.addEventListener('slotchange', () => {
            const nodes = this.querySelectorAll('option, optgroup');
            nodes.forEach((node) => {
                if (node) {
                    if (!this.value) {
                        if (
                            isBooleanAttributeTrue(
                                node.getAttribute('selected')
                            )
                        ) {
                            if (node.value) {
                                selectedOptionValue = node.value;
                            } else {
                                selectedOptionValue = '';
                            }
                        }
                    }
                    node.removeAttribute('selected');
                    this.$options.push(node);
                }
            });
            this._insertOptions();
            if (this.value === null || this.value === undefined) {
                this.value = selectedOptionValue;
                this.$internals.setFormValue(selectedOptionValue);
            }
        });
        this._bind();
    }

    _insertSelectFromTemplate = () => {
        const ddTemplate = this.$shadowRoot.querySelector('#dropdown-template');
        this.$shadowRoot.appendChild(ddTemplate.content.cloneNode(true));
    };

    _handleInput = (event) => {
        if (this.readonly) {
            event.preventDefault();
        } else {
            this.value = event.target.value;
            if (!this.fallbackmode) {
                this.$internals.setFormValue(this.value);
            }
            this.dispatchEvent(
                new CustomEvent('change', {
                    bubbles: true,
                    detail: {
                        value: this.value,
                    },
                })
            );
        }
    };

    _insertOptions = () => {
        const select = this.$shadowRoot.querySelector('select');
        let selectedIndex = 0;
        this.$options.forEach((option, index) => {
            if (option.value === this.value) {
                selectedIndex = index;
            }
            select.append(option);
        });
        select.selectedIndex = selectedIndex;
    };

    _getSelectedOptionValue = () => {
        let value = '';
        const selectedOption = this.$select.querySelector(
            "option[selected='selected']"
        );
        if (selectedOption) {
            value = selectedOption.getAttribute('value');
        }
        return value;
    };

    /**
     * Sets the selected option to the content of the value attribute
     *
     * Checks if there is an option that is marked as selected in the DOM
     * Checks if the selected option === the value attribute
     * Removes the selected attribute if not equal to the value attribute
     * Sets the correct option as selected
     * @returns void
     */
    _setSelectedOption = () => {
        const selectedOption = this.querySelector('option[selected]');
        if (selectedOption && selectedOption.value !== this.value) {
            selectedOption.setAttribute('selected', false);
        }
        this.querySelectorAll('option').forEach((option) => {
            if (option.value === this.value) {
                option.setAttribute('selected', true);
            }
        });
    };

    _bind = () => {
        this.$select = this.$shadowRoot.querySelector('select');
        this.$select?.addEventListener('change', this._handleInput);
        this._bindInfoClick();
    };

    _unbind = () => {
        this.$select?.removeEventListener('change', this._handleInput);
        this._unbindInfoClick();
    };

    _bindInfoClick = () => {
        const infoIcon = this.$shadowRoot.querySelector('label > sketch-icon');
        if (infoIcon) {
            infoIcon.addEventListener('click', this._handleInfoClick);
        }
    };

    _unbindInfoClick = () => {
        const infoIcon = this.$shadowRoot.querySelector('label > sketch-icon');
        if (infoIcon) {
            infoIcon.removeEventListener('click', this._handleInfoClick);
        }
    };

    _handleInfoClick = () => {
        this.dispatchEvent(
            new MouseEvent('inforequested', {
                bubbles: true,
            })
        );
    };

    attributeChangedCallback(property, oldValue, newValue) {
        this._unbind();
        this.render();
        this._insertSelectFromTemplate();
        this._insertOptions();
        this._bind();
    }

    render() {
        const cssClasses = this.hasError === 'true' ? 'error' : '';
        this.$shadowRoot.innerHTML = `
        <style>
        ${styles}
        :host {
            display: flex;
            flex-direction: column;
            grid-column: span 2;
            ${this.nomargin ? '' : 'margin-bottom: var(--sketchSpacing6);'}
        }
        ${this.readonly ? 'select {pointer-events: none;}' : ''}

        label {
            ${this.subgrid ? '' : `margin-bottom: var(--sketchSpacing1);`}
            ${this.hasinfo ? 'display: flex;align-items: center;' : ''}

            sketch-icon:hover {
                cursor: pointer;
            }
        }
        
        @media only screen and (min-width: 576px) {
            :host {
                ${this.nomargin ? '' : 'margin-bottom: var(--sketchSpacing6);'}
                ${
                    this.subgrid
                        ? `display: grid;
                grid-template-columns: subgrid;`
                        : ''
                }
                
            }
            label {
                display: flex;
                align-items: center;
            }
        }
        </style>
        <template id="dropdown-template">
            <label for="${this.fieldId}">
                <sketch-text variant="Label" color=${
                    this.hasError === 'true' ? 'error' : ''
                }>
                    ${this.label}
                </sketch-text>
                ${this.hasinfo ? '<sketch-icon icon="info" size="xs"></sketch-icon>' : ''}
            </label>
            <select
                id="${this.fieldId}"
                class="sketch-select ${cssClasses}"
                name="${this.name}"
                ${this.required ? 'required' : ''}
                ${this.disabled ? 'disabled' : ''}
                ${this.readonly ? 'readonly="readonly"' : ''}
                >
            </select>
            <slot></slot>
        </template>`;
    }
}
