import {remToPx} from '../utils/index.js';

export class SketchLoader extends HTMLElement {
    /* for form elements */
    static formAssociated = true;

    static get observedAttributes() {
        return ['size', 'color'];
    }

    constructor() {
        super();
        /* for form elements */
        this._internals = this.attachInternals();
        this.shadow = this.attachShadow({mode: 'open', delegatesFocus: true});
    }

    get size() {
        const fontRootSize = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        let size = 40;
        switch (this.getAttribute('size')) {
            case 'xs':
                size = remToPx(0.8, fontRootSize);
                break;
            case 'sm':
                size = remToPx(1, fontRootSize);
                break;
            case 'md':
                size = remToPx(1.2, fontRootSize);
                break;
            case 'lg':
                size = remToPx(2, fontRootSize);
                break;
            case 'xl':
                size = remToPx(4, fontRootSize);
                break;
            default:
                try {
                    size = Number(this.getAttribute('size'));
                } catch (e) {
                    size = 40;
                } finally {
                    if (Number.isNaN(size)) {
                        size = 40;
                    }
                }
                break;
        }
        return size;
    }

    set size(value) {
        this.setAttribute('size', value);
    }

    get color() {
        const color = this.getAttribute('color') || 'Primary';
        return `${color.slice(0, 1).toUpperCase()}${color.slice(1)}`;
    }

    set color(value) {
        this.setAttribute('color', value);
    }

    connectedCallback() {
        this.render();
    }

    render() {
        const margin = this.size * 0.1;
        const borderWidth = this.size * 0.1;
        this.shadow.innerHTML = `
        <style>       
        :host {
            width: ${this.size}px;
            height: ${this.size}px;
        } 
        .loader {
            color: var(--sketchColor${this.color});
        }
        .loader,
        .loader div {
            box-sizing: border-box;
        }
        .loader {
            display: inline-block;
            position: relative;
            width: ${this.size}px;
            height: ${this.size}px;
        }
        .loader div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: calc(${this.size}px - ${margin}px * 2);
            height: calc(${this.size}px - ${margin}px * 2);
            margin: ${margin}px;
            border: ${borderWidth}px solid currentColor;
            border-radius: 50%;
            animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: currentColor transparent transparent transparent;
        }
        .loader div:nth-child(1) {
            animation-delay: -0.45s;
        }
        .loader div:nth-child(2) {
            animation-delay: -0.3s;
        }
        .loader div:nth-child(3) {
            animation-delay: -0.15s;
        }
        @keyframes loader {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
        }
        </style>
        <div class="loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>`;
    }
}
