export class SketchBadge extends HTMLElement {
    static get observedAttributes() {
        return [];
    }

    constructor() {
        super();
        this.shadow = this.attachShadow({mode: 'open', delegatesFocus: true});
    }

    get variant() {
        return this.getAttribute('variant');
    }

    set variant(value) {
        this.setAttribute('variant', value);
    }

    connectedCallback() {
        this.render();
    }

    attributeChangedCallback(property, oldValue, newValue) {
        this.render();
    }

    render() {
        this.shadow.innerHTML = `
        <style>
            :host {
                display: inline-block;
            }
            .outerbadge {
                border-radius: var(--sketchBorderRadiusBadge, 25px);
                border: var(--sketchBorderBadgeOutside, 1px solid #cecece);
            }
            .badge {
                font-size: var(--sketchFontSizeBadge, .8rem);
                background-color: var(--sketchBackgroundColorBadge, red);
                color: var(--sketchColorBadge, white);
                font-weight: var(--sketchFontWeightBadge, bold);
                border: var(--sketchBorderBadge, 3px solid white);
                padding: var(--sketchPaddingBadge, var(--sketchSpacing2));
                border-radius: var(--sketchBorderRadiusBadge, 25px);
            }
        </style>
        <div class="outerbadge">
            <div class="badge"><slot></slot></div>
        </div>`;
    }
}
