import {isBooleanAttributeTrue} from '../utils/index.js';

export class SketchProgressbar extends HTMLElement {
    static get observedAttributes() {
        return ['message', 'percentage', 'variant', 'asCard'];
    }

    constructor() {
        super();
        this.shadow = this.attachShadow({mode: 'open', delegatesFocus: true});
    }

    get variant() {
        return this.getAttribute('variant');
    }

    set variant(value) {
        this.setAttribute('variant', value);
    }

    get percentage() {
        return this.getAttribute('percentage') || '0';
    }

    set percentage(value) {
        this.setAttribute('percentage', value);
    }

    get message() {
        return this.getAttribute('message');
    }

    set message(value) {
        this.setAttribute('message', value);
    }

    get asCard() {
        return isBooleanAttributeTrue(this.getAttribute('asCard'));
    }

    set asCard(value) {
        this.setAttribute('asCard', value);
    }

    connectedCallback() {
        this.render();
    }

    attributeChangedCallback(property, oldValue, newValue) {
        this.render();
    }

    render() {
        this.shadow.innerHTML = `
        <style>
            .wrapper {
                ${
                    this.asCard
                        ? `
                    border: var(--sketchBorderSection);
                    border-top-left-radius: var(--sketchBorderRadiusSection);
                    border-top-right-radius: var(--sketchBorderRadiusSection);`
                        : ``
                }
                
                padding-top: var(--sketchSpacing4);

                progress {
                    box-sizing: border-box;
                    display: inline-block;
                    vertical-align: baseline;
                    border-radius: var(--sketchBorderRadius, 0);
                    height: 5px;
                    width: 100%;
                }

                progress::-webkit-progress-bar {
                    background-color: #f0ecec;
                    border-radius: var(--sketchBorderRadius, 0);
                }
                progress::-webkit-progress-value {
                    background-color: var(--sketchColorNeutralDark);
                    border-radius: var(--sketchBorderRadius, 0);
                }

                .info {
                    ${this.asCard ? `padding-left: var(--sketchSpacing6);` : ``}
                    display: flex;
                    align-items: center;

                    #percentage {
                        margin-right: var(--sketchSpacing2);
                    }
                }

                &.success {
                    progress::-webkit-progress-value {
                        background-color: var(--sketchColorSuccess);
                    }
                }
                &.warning {
                    progress::-webkit-progress-value {
                       background-color: var(--sketchColorWarning);
                    }
                }
                &.error {
                    progress::-webkit-progress-value {
                       background-color: var(--sketchColorError);
                    }
                }
            }

        </style>
        <div class="wrapper ${this.variant}">
            <div class="info">
                <sketch-text id="percentage" variant="BodyCopy" ${this.variant !== 'default' ? `color="${this.variant}"` : ''}>${this.percentage}%</sketch-text>
                ${this.message ? `<sketch-text variant="Footnote">${this.message}</sketch-text>` : ``}
            </div>
            <progress max="100" value="${this.percentage}"></progress>
        </div>`;
    }
}
