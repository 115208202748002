// eslint-disable-next-line import/no-extraneous-dependencies
import {Datepicker} from 'vanillajs-datepicker';
import nl from 'vanillajs-datepicker/locales/nl';
import fr from 'vanillajs-datepicker/locales/fr';
import {
    dateToDollarH,
    dollarHToJSDate,
    isBooleanAttributeTrue,
} from '../utils/index.js';

import styles from './input.style.css';

const formatAsIsoDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

export class SketchDate extends HTMLElement {
    static formAssociated = true;

    $shadowRoot;

    $internals;

    $fallbackmode = false;

    $dateField;

    $displayField;

    $datePicker;

    static get observedAttributes() {
        return [
            'label',
            'name',
            'readonly',
            'value',
            'displayformat',
            'disabled',
            'hasError',
            'required',
            'fieldId',
            'language',
            'mode',
            'minDate',
            'maxDate',
            'subgrid',
        ];
    }

    constructor() {
        super();
        try {
            this.$internals = this.attachInternals();
        } catch (error) {
            this.$fallbackmode = true;
        }
        const delegateFocus = !this.$fallbackmode;
        this.$shadowRoot = this.attachShadow({
            mode: 'open',
            delegatesFocus: delegateFocus,
        });
        Object.assign(Datepicker.locales, nl, fr);
    }

    get mode() {
        return this.getAttribute('mode') || 'mumps';
    }

    set mode(value) {
        this.setAttribute('mode', value);
    }

    get fieldId() {
        return this.getAttribute('fieldId');
    }

    set fieldId(value) {
        this.setAttribute('fieldId', value);
    }

    get required() {
        return isBooleanAttributeTrue(this.getAttribute('required'));
    }

    set required(value) {
        this.setAttribute('required', value);
    }

    get hasError() {
        return this.getAttribute('hasError');
    }

    set hasError(value) {
        this.setAttribute('hasError', value);
    }

    get disabled() {
        return isBooleanAttributeTrue(this.getAttribute('disabled'));
    }

    set disabled(value) {
        this.setAttribute('disabled', value);
    }

    get displayformat() {
        const fallback = 'dd/mm/yyyy';
        const value = this.getAttribute('displayformat');
        if (value) {
            return value;
        }
        return fallback;
    }

    set displayformat(value) {
        this.setAttribute('displayformat', value);
    }

    get label() {
        return this.getAttribute('label');
    }

    set label(value) {
        this.setAttribute('label', value);
    }

    get name() {
        return this.getAttribute('name');
    }

    set name(value) {
        this.setAttribute('name', value);
    }

    get language() {
        return this.getAttribute('language') || 'nl';
    }

    set language(value) {
        this.setAttribute('language', value);
    }

    get readonly() {
        return isBooleanAttributeTrue(this.getAttribute('readonly'));
    }

    set readonly(value) {
        this.setAttribute('readonly', value);
    }

    get minDate() {
        const value = this.getAttribute('minDate');
        if (value) {
            return value;
        }
        return null;
    }

    set minDate(value) {
        this.setAttribute('minDate', value);
    }

    get maxDate() {
        const value = this.getAttribute('maxDate');
        if (value) {
            return value;
        }
        return null;
    }

    set maxDate(value) {
        this.setAttribute('maxDate', value);
    }

    get value() {
        const value = this.getAttribute('value');
        if (value) {
            return value;
        }
        return '';
    }

    set value(value) {
        this.setAttribute('value', value);
    }

    get subgrid() {
        return isBooleanAttributeTrue(this.getAttribute('subgrid'));
    }

    set subgrid(value) {
        this.setAttribute('subgrid', value);
    }

    get fallbackmode() {
        return isBooleanAttributeTrue(this.getAttribute('fallbackmode'));
    }

    set fallbackmode(value) {
        this.setAttribute('fallbackmode', value);
    }

    connectedCallback() {
        if (this.$fallbackmode) {
            this.fallbackmode = true;
        }
        this.render();
        this._bind();
    }

    attributeChangedCallback(property, oldValue, newValue) {
        if (
            (property === 'value' && oldValue !== newValue) ||
            (property === 'minDate' && oldValue !== newValue)
        ) {
            this._unbind();
            this.render();
            this._bind();
        }
    }

    _unbind = () => {
        this.$displayField?.removeEventListener(
            'changeDate',
            this._handleDateChange
        );
    };

    _bind = () => {
        this.$displayField =
            this.$shadowRoot.querySelector('input[type="text"]');
        this.$dateField = this.$shadowRoot.querySelector(
            'input[type="hidden"]'
        );
        if (!this.readonly && !this.disabled) {
            const datepickerOptions = {
                format: this.displayformat,
                weekStart: 1, // start week on monday
                autohide: true,
                language: this.language,
                // todayHighlight: true,
            };
            if (this.minDate) {
                if (this.mode === 'js') {
                    datepickerOptions.minDate = new Date(this.minDate);
                } else {
                    datepickerOptions.minDate = dollarHToJSDate(this.minDate);
                }
            }
            if (this.maxDate) {
                if (this.mode === 'js') {
                    datepickerOptions.maxDate = new Date(this.maxDate);
                } else {
                    datepickerOptions.maxDate = dollarHToJSDate(this.maxDate);
                }
            }
            this.$datePicker = new Datepicker(
                this.$displayField,
                datepickerOptions
            );
            const icon = this.$shadowRoot.querySelector('sketch-icon');
            if (icon) {
                icon.addEventListener('click', () => {
                    this.$datePicker.show();
                });
            }
        }
        this._setDisplayValue();
        this.$displayField.addEventListener(
            'changeDate',
            this._handleDateChange
        );
        if (!this.fallbackmode) {
            this.$internals?.setFormValue(this.value);
            this.$internals?.setValidity(
                this.$dateField.validity,
                this.$dateField.validationMessage,
                this.$displayField
            );
        }
    };

    _handleDateChange = (event) => {
        const selectedDate = event.detail.date;
        if (selectedDate) {
            if (this.mode === 'mumps') {
                this.value = dateToDollarH(selectedDate, this.$dateField.value);
            } else {
                this.value = new Date(
                    `${formatAsIsoDate(selectedDate)}T00:00:00.000Z`
                ).toISOString();
            }
            this.$datePicker.setDate(selectedDate);
        } else {
            // date has been cleared
            this.value = '';
            this.$datePicker.setDate({clear: true});
        }
        this.dispatchEvent(
            new CustomEvent('change', {
                bubbles: true,
                detail: {
                    value: this.value,
                },
            })
        );
    };

    _setDisplayValue = () => {
        let dateValue = new Date();
        if (this.value) {
            if (this.mode === 'mumps') {
                dateValue = dollarHToJSDate(this.$dateField.value);
            } else {
                // parse ISO 8601 date string to javascript date
                dateValue = new Date(this.value);
            }
            const day = `0${dateValue.getDate()}`.slice(-2);
            const month = `0${dateValue.getMonth() + 1}`.slice(-2);
            const year = dateValue.getFullYear();
            let displayDay = this.displayformat.replace('dd', day);
            displayDay = displayDay.replace('mm', month);
            displayDay = displayDay.replace('yyyy', year);
            this.$displayField.value = displayDay;
            if (this.$datePicker) {
                this.$datePicker.setDate(dateValue);
            }
        }
    };

    render() {
        const cssClasses = this.hasError === 'true' ? 'error' : '';
        this.$shadowRoot.innerHTML = `
        <style>
        .datepicker{
            width:-moz-min-content;
            width:min-content;
        }
        .datepicker:not(.active){
            display:none;
        }
        .datepicker-dropdown{
            padding-top:4px;
            position:absolute;
            z-index:20;
        }
        .datepicker-dropdown.datepicker-orient-top{
            padding-bottom:4px;
            padding-top:0
        }
        .datepicker-picker{
            background-color:#fff;
            border-radius:4px;
            display:flex;
            flex-direction:column;
        }
        .datepicker-dropdown .datepicker-picker{
            box-shadow:0 2px 3px hsla(0,0%,4%,.1),0 0 0 1px hsla(0,0%,4%,.1);
        }
        .datepicker-main{
            flex:auto;
            padding:2px;
        }
        .datepicker-footer{
            background-color:#f5f5f5;
            box-shadow:inset 0 1px 1px hsla(0,0%,4%,.1);
        }.datepicker-title{
            background-color:#f5f5f5;
            box-shadow:inset 0 -1px 1px hsla(0,0%,4%,.1);
            font-weight:700;
            padding:.375rem .75rem;
            text-align:center;
        }
        .datepicker-controls{
            display:flex;
        }
        .datepicker-header .datepicker-controls{
            padding:2px 2px 0;
        }
        .datepicker-controls .button{
            align-items:center;
            background-color:#fff;
            border:1px solid #dcdcdc;
            border-radius:4px;
            box-shadow:none;
            color:var(--sketchColorPrimary);
            cursor:pointer;
            display:inline-flex;
            font-size:1rem;
            height:2.25em;
            justify-content:center;
            line-height:1.5;
            margin:0;
            padding:calc(.375em - 1px) .75em;
            position:relative;
            text-align:center;
            vertical-align:top;
            white-space:nowrap;
        }
        .datepicker-controls .button:active,.datepicker-controls .button:focus{
            outline:none;
        }
        .datepicker-controls .button:hover{
            border-color:#b8b8b8;
            color:#363636;
        }
        .datepicker-controls .button:focus{
            border-color:#3273dc;
            color:#363636;
        }
        .datepicker-controls .button:focus:not(:active){
            box-shadow:0 0 0 .125em rgba(50,115,220,.25);
        }
        .datepicker-controls .button:active{
            border-color:#474747;
            color:#363636;
        }
        .datepicker-controls .button[disabled]{
            cursor:not-allowed;
        }
        .datepicker-header .datepicker-controls .button{
            border-color:transparent;
            font-weight:700;
        }
        .datepicker-header .datepicker-controls .button:hover{
            background-color:#f9f9f9;
        }
        .datepicker-header .datepicker-controls .button:active{
            background-color:#f2f2f2;
        }
        .datepicker-footer .datepicker-controls .button{
            border-radius:2px;
            flex:auto;
            font-size:.75rem;
            margin:calc(.375rem - 1px) .375rem;
        }
        .datepicker-controls .view-switch{
            flex:auto;
        }
        .datepicker-controls .next-button,.datepicker-controls .prev-button{
            flex:0 0 14.2857142857%;
            padding-left:.375rem;
            padding-right:.375rem;
        }
        .datepicker-controls .next-button.disabled,.datepicker-controls .prev-button.disabled{
            visibility:hidden;
        }
        .datepicker-grid,.datepicker-view{
            display:flex;
        }
        .datepicker-view{
            align-items:stretch;
            width:15.75rem;
        }
        .datepicker-grid{
            flex:auto;
            flex-wrap:wrap;
        }
        .datepicker .days{
            display:flex;
            flex:auto;
            flex-direction:column;
        }
        .datepicker .days-of-week{
            display:flex;
            background-color: var(--sketchColorPrimaryExtraLight);
            padding-top: 2px;
            padding-bottom: 2px;
        }
        .datepicker .week-numbers{
            display:flex;
            flex:0 0 9.6774193548%;
            flex-direction:column;
        }
        .datepicker .weeks{
            align-items:stretch;
            display:flex;
            flex:auto;
            flex-direction:column;
        }
        .datepicker span{
            -webkit-touch-callout:none;
            align-items:center;
            border-radius:4px;
            cursor:default;
            display:flex;
            justify-content:center;
            -webkit-user-select:none;
            -moz-user-select:none;
            user-select:none;
        }.
        datepicker .dow{
            font-size:.875rem;
            font-weight:700;
            height:1.5rem;
        }
        .datepicker .week{
            color:#b8b8b8;
            flex:auto;
            font-size:.75rem;
        }
        .datepicker .days .dow,.datepicker-cell{
            flex-basis:14.2857142857%;
        }
        .datepicker-cell{
            height:2.25rem;
        }
        .datepicker-cell:not(.day){
            flex-basis:25%;
            height: 4.5rem;
        }
        .datepicker-cell:not(.disabled):hover {
            background-color: #f9f9f9;
            cursor: pointer;
        }
        .datepicker-cell.focused:not(.selected) {
            //background-color: #e9e9e9;
        }
        .datepicker-cell.selected,
        .datepicker-cell.selected:hover {
            background-color: var(--sketchBackgroundColorPrimary);
            color: #fff;
            font-weight: 600;
        }
        .datepicker-cell.disabled {
            color: #dcdcdc;
        }
        .datepicker-cell.next:not(.disabled),
        .datepicker-cell.prev:not(.disabled) {
            color: #7a7a7a;
        }
        .datepicker-cell.next.selected,
        .datepicker-cell.prev.selected {
            color: #e6e6e6;
        }
        .datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
            background-color: #f5f5f5;
            border-radius: 0;
        }
        .datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(
                .disabled
            ):hover {
            background-color: #efefef;
        }
        .datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
            background-color: #e9e9e9;
        }
        .datepicker-cell.today:not(.selected) {
            background-color: var(--sketchBackgroundColorBody);
            color: var(--sketchColorPrimary);
            border: 1px solid var(--sketchColorPrimary);
        }
        .datepicker-cell.today:not(.selected):not(.disabled) {
            color: var(--sketchColorPrimary);
        }
        .datepicker-cell.today.focused:not(.selected) {
            // background-color: #00ccad;
        }
        .datepicker-cell.range-end:not(.selected),
        .datepicker-cell.range-start:not(.selected) {
            background-color: #b8b8b8;
            color: #fff;
        }
        .datepicker-cell.range-end.focused:not(.selected),
        .datepicker-cell.range-start.focused:not(.selected) {
            background-color: #b3b3b3;
        }
        .datepicker-cell.range-start:not(.range-end) {
            border-radius: 4px 0 0 4px;
        }
        .datepicker-cell.range-end:not(.range-start) {
            border-radius: 0 4px 4px 0;
        }
        .datepicker-cell.range {
            background-color: #dcdcdc;
            border-radius: 0;
        }
        .datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
            background-color: #d7d7d7;
        }
        .datepicker-cell.range.disabled {
            color: #c6c6c6;
        }
        .datepicker-cell.range.focused {
            background-color: #d1d1d1;
        }
        .datepicker-input.in-edit {
            border-color: #276bda;
        }
        .datepicker-input.in-edit:active,
        .datepicker-input.in-edit:focus {
            box-shadow: 0 0 0.25em 0.25em rgba(39, 107, 218, 0.2);
        }
        ${styles}
        :host {
            display: flex;
            flex-direction: column;
            grid-column: span 2;
            ${
                this.nomargin === 'true'
                    ? ''
                    : 'margin-bottom: var(--sketchSpacing6);'
            }
        }
        .inputwrapper {
            display: flex;
            align-items: center;
        }
        sketch-icon {
            position: relative;
            left: -20px;
            display: flex;
        }
        ${
            this.subgrid
                ? ''
                : `
                label {
                    margin-bottom: var(--sketchSpacing1);
                }`
        }
        @media only screen and (min-width: 576px) {
            :host {
                ${
                    this.nomargin === 'true'
                        ? ''
                        : 'margin-bottom: var(--sketchSpacing6);'
                }
                ${
                    this.subgrid
                        ? `display: grid;
                grid-template-columns: subgrid;`
                        : ''
                }
                
            }
            label {
                display: flex;
                align-items: center;
            }
        }
        </style>
        <label for="${this.fieldId}"
            ><sketch-text variant="Label" color=${
                this.hasError === 'true' ? 'error' : ''
            }
                >${this.label}</sketch-text
            >
        </label>
        <input type="hidden" name="${this.name}" value="${this.value}" ${this.required ? 'required' : ''}/>
        <div class="inputwrapper">
            <input
                class="${cssClasses}"
                id="${this.fieldId}"
                type="text"
                ${this.disabled ? 'disabled' : ''}
                ${this.required ? 'required' : ''}
                ${this.readonly ? 'readonly="readonly"' : ''}
    }
                />
            <sketch-icon icon="calendar-check-o" size="sm"></sketch-icon>
        </div>`;
    }
}
